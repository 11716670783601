import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Modal from '@material-ui/core/Modal'

import DiscountDetails from 'components/shop/DiscountDetails'
import DepositDetails from 'components/shop/DepositDetails'
import { getCartDepositDetails, getTotalDue, getTotalDiscount, getSubTotal } from 'reducers/shop'
import { getEuroLabel } from 'utils/text'

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center'
  },
  header: {
    padding: 15,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '18.75px',
    textAlign: 'left',
    width: '100%'
  },
  body: {
    display: 'flex',
    flexFlow: 'column nowrap',
    backgroundColor: '#ffffff',
    padding: '5px 10px',
    width: '100%',
    maxWidth: '800px'
  },
  summaryItem: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '35px'
  },
  itemName: {
    minWidth: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  itemValue: {
    flexShrink: 0,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center'
  },
  infoIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: 10,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.65
    },
    color: 'grey'
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none'
  },
  deposit: {
    color: 'green'
  }
})

const ExpensesSummary = ({ showHeader = true, showDiscountInfo = false, showCoupon = false }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalDepositOpen, setIsModalDepositOpen] = useState(false)
  const totalDue = useSelector(getTotalDue)
  const totalDiscount = useSelector(getTotalDiscount)
  const deposit = useSelector(getCartDepositDetails)
  const subTotal = useSelector(getSubTotal)
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {showHeader
        ? <div className={classes.header}>Récapitulatif du paiement</div>
        : null}
      <div className={classes.body}>
        <div className={classes.summaryItem}>
          <div className={classes.itemName}>Sous-total</div>
          <div className={classes.itemValue}>{getEuroLabel({ count: subTotal })}</div>
        </div>
        {deposit.quantity > 0 &&
          <div className={clsx(classes.summaryItem, classes.deposit)}>
            <div className={classes.itemValue}>
              <div className={classes.itemName}>Consigne ({deposit.quantity})</div>
              <InfoOutlinedIcon className={classes.infoIcon} fontSize='small' onClick={() => setIsModalDepositOpen(true)} />
            </div>
            <div className={classes.itemValue}>
              <div>+ {getEuroLabel({ count: deposit.total })}</div>
            </div>
          </div>}
        <div className={classes.summaryItem}>
          <div className={classes.itemValue}>
            <div className={classes.itemName}>Remise et cagnotte</div>
            {showDiscountInfo && <InfoOutlinedIcon className={classes.infoIcon} fontSize='small' onClick={() => setIsModalOpen(true)} />}
          </div>
          <div className={classes.itemValue}>
            <div>{totalDiscount === 0 ? '' : '- '}{getEuroLabel({ count: totalDiscount })}</div>
          </div>
        </div>
        <div className={classes.summaryItem} style={{ fontWeight: 700 }}>
          <div className={classes.itemName}>Total</div>
          <div className={classes.itemValue}>{getEuroLabel({ count: totalDue })}</div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby='remises appliquées'
        aria-describedby='détail des remises appliquées'
        className={classes.modal}
      >
        <DiscountDetails showCoupon={showCoupon} onClose={() => setIsModalOpen(false)} />
      </Modal>
      <Modal
        open={isModalDepositOpen}
        onClose={() => setIsModalDepositOpen(false)}
        aria-labelledby='fonctionnement de la consigne'
        aria-describedby='Fonctionnement des contenants réutilisables et de la consigne'
        className={classes.modal}
      >
        <DepositDetails onClose={() => setIsModalDepositOpen(false)} />
      </Modal>
    </div>
  )
}

export default ExpensesSummary
