import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import ContentWrapper from 'components/layout/ContentWrapper'
import ShopInfoMessage from 'components/shop/ShopInfoMessage'
import CartPanel from './CartPanel'
import Publications from './Publications'
import actionTypes from 'actions/types'
import { getShopInfoMessage } from 'reducers/shop'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) 341px',
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'minmax(0, 1fr) 400px'
    }
  },
  modal: {
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const index = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const infoMessage = useSelector(getShopInfoMessage)

  const clearInfoMessage = () => dispatch({ type: actionTypes.CLEAR_SHOP_INFO_MESSAGE_ACTION })

  return (
    <ContentWrapper
      paddingSpacing={0}
      className={classes.root}
    >
      <Publications />
      <CartPanel />
      <Modal
        open={!!infoMessage}
        onClose={clearInfoMessage}
        aria-labelledby="Modale d'information"
        className={classes.modal}
      >
        <ShopInfoMessage onClose={clearInfoMessage} />
      </Modal>
    </ContentWrapper>
  )
}

export default index
