import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    backgroundColor: 'white'
  },
  tab: {
    minWidth: 140
  }
})

const PublicationsFilter = ({ activeFilter, filters, onChangeFilter }) => {
  const classes = useStyles()
  const _onChange = (event, value) => {
    onChangeFilter(value)
  }
  return (
    <div className={classes.root}>
      <Tabs
        value={activeFilter}
        onChange={_onChange}
        scrollButtons='on'
        variant='scrollable'
        indicatorColor='primary'
        textColor='primary'
      >
        {
          filters && filters.map(filterItem => (
            <Tab label={filterItem.label} key={filterItem.filter} disableRipple className={classes.tab} />
          ))
        }
      </Tabs>
    </div>
  )
}

export default PublicationsFilter
