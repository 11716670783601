import React from 'react'
import { create } from 'jss'
import preset from 'jss-preset-default'
import { createGenerateId, JssProvider } from 'react-jss'
import { MuiThemeProvider, createMuiTheme, createGenerateClassName } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'

// code taken from https://material-ui.com/guides/right-to-left/
const jss = create(preset())

// very important for react-await-dialog
const generateClassName = createGenerateClassName()
const generateId = createGenerateId()

const theme = createMuiTheme({
  palette: {
    primary: {
      main: blue[500] // #2196F3
    },
    action: {
      disabledBackground: '#808080',
      disabled: 'white'
    }
  },
  typography: {
    useNextVariants: true
  }
})

const CustomThemeProvider = ({ children }) => {
  return (
    <JssProvider jss={jss} generateClassName={generateClassName} generateId={generateId}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </JssProvider>
  )
}

export default CustomThemeProvider
