import * as api from 'api/users'
import {
  simpleAction
} from './utils'
import { logout } from './auth'
import actionTypes from 'actions/types'

const { UPDATE_USER_ACTION, GET_ME_LOADING_END_ACTION, GET_ME_LOADING_START_ACTION, LOGOUT_ACTION, CLEAR_LOGIN_ERROR_ACTION } = actionTypes

export const getMe = ({ needToUpdate = true }) => {
  return simpleAction({ start: GET_ME_LOADING_START_ACTION, end: GET_ME_LOADING_END_ACTION, errorType: LOGOUT_ACTION }, async (dispatch) => {
    try {
      const result = await api.getMe()
      if (result.error) {
        throw new Error('Failed to get user information')
      } else {
        if (!needToUpdate) return
        dispatch(updateUser(result.data))
      }
    } catch {
      dispatch(logout())
    }
  })
}

export const updateUser = (userInformation = {}) => {
  return dispatch => {
    const { email, firstName, id, lastName } = userInformation
    dispatch({
      type: UPDATE_USER_ACTION,
      user: { email, firstName, id, lastName }
    })
  }
}

export const clearLoginError = () => {
  return { type: CLEAR_LOGIN_ERROR_ACTION }
}
