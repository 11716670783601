import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getPaymentMethodSelected, getCartError, getTotalDue, MAXIMUM_TR_CREDIT } from 'reducers/shop'
import { getEuroLabel } from 'utils/text'
import { roundCurrency } from 'utils/numerals'
import { clearCheckoutError, payByTRP, selectPaymentMethod } from 'actions/shop/cart'

import ClearIcon from '@material-ui/icons/Clear'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'

import CartHeader from 'components/shop/CartHeader'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '12px 10px',
    backgroundColor: '#ffffff',
    fontWeight: 'bold',
    marginBottom: '5px'
  },
  itemLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  input: {
    backgroundColor: 'white',
    marginBottom: '5px',
    paddingTop: '15px'
  },
  inputTitle: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center'
  },
  action: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px'
  },
  info: {
    textAlign: 'center',
    margin: '50px'
  },
  subInfo: {
    color: '#9B9B9B',
    marginBottom: '10px'
  },
  textField: {
    width: '100%',
    paddingRight: '30px',
    marginLeft: '15px',
    margin: theme.spacing(2)
  },
  helperText: {
    textAlign: 'center'
  },
  checkoutError: {
    color: '#BF0000',
    display: 'flex',
    padding: '10px',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    alignItems: 'center'
  },
  clearIcon: {
    color: 'grey',
    cursor: 'pointer'
  }
}))

const TRPayment = ({ onPrev, onNext, step, currentStep }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { title, sort } = step

  const [value, setValue] = useState('')
  const [error, setError] = useState(null)

  const total = useSelector(getTotalDue)
  let totalDue = total - value
  totalDue = totalDue < 0 ? 0 : roundCurrency(totalDue)

  const paymentMethodSelected = useSelector(getPaymentMethodSelected)
  const checkoutError = useSelector(getCartError)
  const setCheckoutError = () => dispatch(clearCheckoutError())

  useEffect(() => {
    if (value > MAXIMUM_TR_CREDIT) setError(`Le montant maximum autorisé est de ${MAXIMUM_TR_CREDIT}€`)
    if (error && value <= MAXIMUM_TR_CREDIT) setError(null)
  }, [value])

  const handleChange = (event) => {
    const newValue = event.target.value
    const newTotal = newValue || ''
    setValue(newTotal)
  }
  const handleCancel = () => {
    onPrev()
    setValue('')
    dispatch(selectPaymentMethod())
    if (checkoutError) dispatch(clearCheckoutError())
  }
  const handleValidate = () => {
    if (paymentMethodSelected.includes('TRP')) dispatch(payByTRP(value, onNext))
    setValue('')
  }

  if (currentStep !== sort) return null
  return (
    <div>
      <CartHeader title={title} showAction={false} handleCancel={handleCancel} />
      <div className={classes.item}>
        <div className={classes.label}>Total à régler</div>
        <div className={classes.label}>{getEuroLabel({ count: total })}</div>
      </div>
      <div className={classes.input}>
        <div className={classes.inputTitle}>Remise en titres restaurant papier</div>
        <TextField
          label='Total TRP'
          size='small'
          type='number'
          value={value}
          onChange={handleChange}
          className={classes.textField}
          helperText={<div className={classes.helperText}>{!error ? 'Saisissez le montant remis en titres restaurant papier. En cas de montant supérieur, la différence sera créditée sur la cagnotte du Client. Validité du crédit : 2 mois.' : error} </div>}
          InputProps={{
            inputProps: { min: 0, max: MAXIMUM_TR_CREDIT },
            endAdornment: <InputAdornment position='end'>€</InputAdornment>
          }}
          error={error}
          variant='outlined'
        />
      </div>
      <div className={classes.item}>
        <div className={classes.label}>Total restant dû</div>
        <div className={classes.label}>{getEuroLabel({ count: totalDue })}</div>
      </div>
      {checkoutError &&
        <div className={classes.checkoutError}> {checkoutError}
          <ClearIcon className={classes.clearIcon} onClick={() => setCheckoutError()} />
        </div>}
      <div className={classes.action}>
        <Button onClick={handleCancel}>Annuler</Button>
        <Button variant='contained' size='medium' color='primary' disabled={(value && totalDue !== 0) || !value || !!error} onClick={handleValidate}>Valider le paiement</Button>
      </div>
      <div className={classes.info}>
        <div className={classes.subInfo}>Vous ne pouvez pas valider le paiement si l'intégralité n'est pas réglée en titres restaurant.</div>
        <div className={classes.subInfo}>Le montant maximal autorisé en TRP est de 19€ / jour.</div>
      </div>
    </div>
  )
}

export default TRPayment
