import React from 'react'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import IconButton from '@material-ui/core/IconButton'

import { getEuroLabel } from 'utils/text'

const useStyles = makeStyles({
  card: {
    flexShrink: 0,
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: '10px 10px 10px 15px',
    '&.MuiPaper-outlined': {
      border: 'none'
    }
  },
  container: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  code: {
    flex: 'none',
    width: 50,
    color: 'black',
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '.3px',
    marginRight: 14
  },
  price: {
    flex: 'none',
    fontSize: 14,
    letterSpacing: '.25px',
    color: 'black',
    marginLeft: 10,
    whiteSpace: 'nowrap',
    marginRight: 5
  },
  icon: {
    cursor: 'pointer',
    color: 'grey'
  },
  title: {
    // maxWidth: 211,
    fontSize: 14,
    letterSpacing: '.25px',
    color: '#9B9B9B',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    flexGrow: 1
  },
  depositIcon: {
    width: 22,
    height: 22
  },
  quantity: {
    textAlign: 'center',
    margin: '0 10px',
    fontWeight: 'bold',
    fontSize: 16
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 28
  },
  actionButton: {
    cursor: 'pointer',
    color: '#9B9B9B',
    width: 28,
    height: 28
  }
})

const CartProduct = ({ idPublication, code, quantity, title, originalPrice, isReusable, updateQuantity }) => {
  if (!idPublication) {
    return null
  }

  const classes = useStyles()
  return (
    <Card variant='outlined' className={classes.card} key={idPublication}>
      <div className={classes.container}>
        <span className={classes.code}>{code}</span>
        <span className={classes.title}>{title}</span>
        <div className={classes.price}>{getEuroLabel({ count: originalPrice * quantity })}</div>
      </div>
      <div className={clsx(classes.container, classes.gap)}>
        {isReusable === true ? <img src='/images/deposit.svg' alt='deposit' className={classes.depositIcon} /> : <span />}
        <div className={classes.actions}>
          <IconButton
            onClick={() => updateQuantity(quantity - 1, idPublication)}
            classes={{ root: classes.actionButton }}
            disableRipple={false}
          >
            <RemoveCircleIcon />
          </IconButton>
          <p className={classes.quantity}>{quantity}</p>
          <IconButton
            onClick={() => updateQuantity(quantity + 1, idPublication)}
            classes={{ root: classes.actionButton }}
            disableRipple={false}
          >
            <AddCircleIcon />
          </IconButton>
        </div>
      </div>
    </Card>
  )
}

export default CartProduct
