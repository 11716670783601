import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { motion } from 'framer-motion'
import debounce from 'lodash/debounce'

import TextField from '@material-ui/core/TextField'

import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles({
  root: {
    width: 70,
    padding: 7,
    background: 'white',
    borderRadius: 70,
    willChange: 'width',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    overflow: 'hidden'
  },
  toggle: {
    width: 56,
    height: 56,
    background: '#2196F3',
    color: 'white',
    '&:hover': {
      color: '#2196F3'
    }
  }
})

const useFormStyles = makeStyles({
  root: {
    width: '77%',
    height: 54,
    margin: '5px 0 0 10px'
  }
})

// remember to always use debounce to avoid heavy rerenderings
const SearchForm = ({ onChange, toggleSearchWidget, inputRef }) => {
  const classes = useFormStyles()
  const update = value => {
    onChange(value)
  }
  const debounceHandleUpdate = debounce((value) => update(value), 1000, { maxWait: 1000 })
  const handleChange = e => {
    e.preventDefault()
    debounceHandleUpdate(e.target.value)
  }

  return (
    <TextField
      margin='none'
      label='Rechercher'
      name='search'
      onChange={handleChange}
      inputRef={inputRef}
      classes={{ root: classes.root }}
      onBlur={() => setTimeout(() => toggleSearchWidget(), 400)}
    />
  )
}

const PublicationSearchWidget = ({ onChangeFilter }) => {
  const [open, setOpen] = useState(false)
  const inputRef = useRef()
  const classes = useStyles()
  const toggle = () => {
    if (open) {
      onChangeFilter('')
    } else {
      if (inputRef && inputRef.current) {
        inputRef.current.value = ''
        inputRef.current.focus()
      }
    }
    setOpen(!open)
  }
  const variants = {
    closed: { width: 70 },
    open: { width: 439 }
  }

  const transition = {
    type: 'linear',
    stiffness: 2000,
    duration: 0.3
  }

  return (
    <motion.div
      animate={open ? 'open' : 'closed'}
      className={classes.root}
      variants={variants}
      transition={transition}
    >
      <IconButton aria-label='toggle' className={classes.toggle} onClick={toggle}>
        <SearchIcon />
      </IconButton>
      <SearchForm
        onChange={onChangeFilter}
        toggleSearchWidget={toggle}
        inputRef={inputRef}
      />
    </motion.div>
  )
}

export default PublicationSearchWidget
