import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import { Formik, Form, Field } from 'formik'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import MenuItem from '@material-ui/core/MenuItem'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'

import { getUser } from 'reducers/app'
import { getCitiesAndHubs, getSectionsError, getSelectedHub } from 'reducers/shop'
import { setSelectedHub } from 'actions/shop/citiesAndHubs'
import { clearSectionsError, getSections } from 'actions/shop/sections'
import { ERROR_HUB_NOT_FOUND, GENERIC_SHOP_FORM_ERROR } from 'utils/errors'

const useStyles = makeStyles({
  root: {
    width: 367,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    margin: '22px'
  },
  noPadding: {
    padding: 0
  },
  header: {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 25
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold'
  },
  content: {
    padding: 0
  },
  input: {
    width: '100%'
  },
  submit: {
    width: '100%',
    marginTop: 25
  },
  error: {
    color: '#BF0000',
    letterSpacing: '0.25px'
  }
})

const ShopSelectorForm = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const citiesAndHubs = useSelector(getCitiesAndHubs)
  const [selectedCity, setSelectedCity] = useState()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const sectionsError = useSelector(getSectionsError)
  const onSelectCity = e => {
    setSelectedCity(e.target.value)
    setIsSubmitDisabled(true)
  }
  const selectedHub = useSelector(getSelectedHub)

  const onSelectHub = e => {
    dispatch(setSelectedHub(e.target.value))
    dispatch(clearSectionsError())
    setIsSubmitDisabled(false)
  }
  return (
    <Card
      elevation={0}
      className={classes.root}
    >
      <CardHeader
        avatar={
          <AccountCircleIcon
            className={classes.icon}
          />
        }
        title={[user?.firstName, user?.lastName].join(' ')}
        className={classes.header}
        classes={{
          title: classes.title
        }}
      />
      <CardContent
        className={classes.content}
      >
        <Formik
          initialValues={{
            city: {
              id_city: '',
              name: '',
              hubs: []
            },
            hub: {
              id_hub: '',
              name: ''
            }
          }}
          onSubmit={() => {
            dispatch(getSections(selectedHub, true))
          }}
        >
          {({ submitForm, values }) => (
            <Form>
              <Field
                component={TextField}
                type='text'
                name='city'
                label='Ville'
                select
                variant='standard'
                margin='normal'
                InputLabelProps={{
                  shrink: true
                }}
                SelectProps={{
                  onChange: onSelectCity,
                  defaultValue: ''
                }}
                className={classes.input}
              >
                {citiesAndHubs?.map((city) => (
                  <MenuItem key={city.idCity} value={city}>
                    {city.name}
                  </MenuItem>
                )) || []}
              </Field>
              <Field
                component={TextField}
                type='text'
                name='hub'
                label='Hub'
                select
                variant='standard'
                margin='normal'
                InputLabelProps={{
                  shrink: true
                }}
                SelectProps={{
                  onChange: onSelectHub,
                  defaultValue: ''
                }}
                disabled={!selectedCity}
                className={classes.input}
              >
                {selectedCity?.hubs.map((hub) => (
                  <MenuItem key={hub.idHub} value={hub}>
                    {hub.name}
                  </MenuItem>
                )) || []}
              </Field>
              {sectionsError && <p className={classes.error}>{sectionsError.type === 'hub_area_not_found' ? ERROR_HUB_NOT_FOUND : GENERIC_SHOP_FORM_ERROR}</p>}
              <Button
                variant='contained'
                color='primary'
                onClick={submitForm}
                disabled={isSubmitDisabled || sectionsError}
                className={classes.submit}
              >
                Commencer
              </Button>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}

export default ShopSelectorForm
