import { useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

import CartHeader from 'components/shop/CartHeader'
import ProductsMenu from 'components/shop/ProductsMenu'
import CartFooter from 'components/shop/CartFooter'

import { getTotalDiscount } from 'reducers/shop'

const TRValidation = ({ products, onNext, step, currentStep }) => {
  const totalDiscount = useSelector(getTotalDiscount)
  const { title, sort } = step
  const stepFields = {
    hasProducts: !isEmpty(products),
    onNext
  }

  if (currentStep !== sort) return null

  return (
    <div>
      <CartHeader title={title} showAction />
      <ProductsMenu products={products} />
      <CartFooter currentStep={currentStep} stepFields={stepFields} showDiscountInfo={totalDiscount > 0} />
    </div>
  )
}

export default TRValidation
