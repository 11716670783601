import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import * as Yup from 'yup'

import Avatar from '@material-ui/core/Avatar'
import CssBaseline from '@material-ui/core/CssBaseline'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import LoginForm from './LoginForm'

import { ERROR_INVALID_EMAIL, ERROR_REQUIRED_FIELD } from 'utils/errors'

import { login } from 'actions/auth'

import { getUserError } from 'reducers/app'

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'grey'
      },
      '&:hover fieldset': {
        borderColor: 'black'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black'
      }
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'black'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: 'black',
    color: 'white',
    '&:hover': {
      backgroundColor: 'black'
    }
  },
  link: {
    color: 'black'
  },
  textfield: {
    '& .Mui-focused': {
      color: 'black'
    }
  },
  error: {
    color: '#BF0000',
    marginTop: '20px'
  }
}))

const Login = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const error = useSelector(getUserError)
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email(ERROR_INVALID_EMAIL)
        .required(ERROR_REQUIRED_FIELD),
      password: Yup.string().required(ERROR_REQUIRED_FIELD)
    }),
    onSubmit: (values) => {
      const { email, password } = values
      dispatch(login({
        email,
        password
      }))
    }
  })

  useEffect(() => {
    const pathname = window.location.pathname
    if (pathname !== '/') {
      window.history.replaceState(null, null, '/')
    }
  }, [])

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Connexion
        </Typography>
        {error && (
          <Typography className={classes.error} variant='body2' align='center'>
            {error}
          </Typography>)}
        <LoginForm classes={classes} formik={formik} />
      </div>
      <Box mt={8}>
        <Typography variant='body2' color='textSecondary' align='center'>
          FoodCheri Back-Office {new Date().getFullYear()}
        </Typography>
      </Box>
    </Container>
  )
}

export default Login
