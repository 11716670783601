import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { HelmetProvider } from 'react-helmet-async'

import Root from 'containers/Root'
import CustomThemeProvider from 'components/CustomThemeProvider'
import { getCurrentDevice } from 'components/layout/Responsive'

import { updateDevice } from 'actions/app'
import { goToRoute } from 'utils/routes'

const App = ({ children }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    window.addEventListener('resize', _updateDevice)
    _updateDevice()
    return () => {
      window.removeEventListener('resize', _updateDevice)
    }
  }, [])

  const _updateDevice = () => {
    dispatch(updateDevice(getCurrentDevice()))
  }

  useEffect(() => {
    dispatch(goToRoute('shop'))
  }, [])

  return (
    <HelmetProvider>
      <CustomThemeProvider>
        <Root>{children}</Root>
      </CustomThemeProvider>
    </HelmetProvider>
  )
}

export default App
