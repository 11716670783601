
import React from 'react'
import { isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'

import CartHeader from 'components/shop/CartHeader'
import ProductsMenu from 'components/shop/ProductsMenu'
import CartFooter from 'components/shop/CartFooter'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start'
  }
})

const CartDetails = ({ products, onNext, step, currentStep }) => {
  const classes = useStyles()
  const { title, sort } = step
  const stepFields = {
    isDisabled: isEmpty(products),
    onNext,
    hasProducts: !isEmpty(products)
  }

  if (currentStep !== sort) return null

  return (
    <div className={classes.root}>
      <CartHeader title={title} showAction={!isEmpty(products)} showExit={isEmpty(products)} />
      <ProductsMenu products={products} />
      <CartFooter currentStep={currentStep} stepFields={stepFields} showCoupon showDiscountInfo />
    </div>
  )
}

export default CartDetails
