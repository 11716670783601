import * as api from 'api/sections'
import * as apiCitiesAndHubs from 'api/citiesAndHubs'
import actionsTypes from 'actions/types'
import sections from 'mockData/sections'
import { simpleAction } from '../utils'
import { showError } from '../errors'
import { getSectionsError } from 'reducers/shop'
import { goToRoute } from 'utils/routes'

export const getSections = (selectedHub, redirect = false) => {
  return simpleAction({ start: actionsTypes.GET_SECTIONS_LOADING_START_ACTION, end: actionsTypes.GET_SECTIONS_LOADING_END_ACTION, errorType: actionsTypes.GET_SECTIONS_ERROR_ACTION }, async (dispatch, getState) => {
    const { data, error } = await api.getSections(selectedHub)
    const sectionsError = getSectionsError(getState())
    if (Array.isArray(data)) {
      await dispatch({
        type: actionsTypes.GET_SECTIONS_SUCCESS_ACTION,
        data
      })
      const { error: errorHub, data: dataHub } = await apiCitiesAndHubs.updateHubPicker(selectedHub)
      if (errorHub || (!dataHub && !errorHub)) {
        dispatch({ type: actionsTypes.SET_HUB_PICKER_ERROR_ACTION, error: errorHub || 'Failed to set hub Picker' })
        return
      }
      if (sectionsError) dispatch({ type: actionsTypes.CLEAR_SECTIONS_ERROR })
      if (redirect) dispatch(goToRoute('pointOfSale'))
    } else await dispatch(showError({ type: actionsTypes.GET_SECTIONS_ERROR_ACTION, error }))
  })
}

export const clearSectionsError = () => (dispatch, getState) => {
  const sectionsError = getSectionsError(getState())
  if (sectionsError) dispatch({ type: actionsTypes.CLEAR_SECTIONS_ERROR })
}

export const getMockSections = ({ success = true, timeout = 500 }) => {
  return simpleAction({ start: actionsTypes.GET_SECTIONS_LOADING_START_ACTION, end: actionsTypes.GET_SECTIONS_LOADING_END_ACTION, errorType: actionsTypes.GET_SECTIONS_ERROR_ACTION }, async dispatch => {
    try {
      const { data } = await new Promise((resolve, reject) => {
        setTimeout(() => {
          if (success) {
            resolve({ data: sections })
          } else {
            reject(new Error('Error happened on getMockSections'))
          }
        }, timeout)
      })
      if (Array.isArray(data)) {
        await dispatch({
          type: actionsTypes.GET_SECTIONS_SUCCESS_ACTION,
          data
        })
      }
    } catch (error) {
      await dispatch(showError({ type: actionsTypes.GET_SECTIONS_ERROR_ACTION, error }))
    }
  })
}
