export const NOT_FOUND = 'not_found'
export const CART_BAD_CUSTOMER = 'cart_bad_customer'
export const INVALID_PARAMETER = 'invalid_parameter'
export const SHIFT_TIMESLOT_BAD_AREA = 'shift_timeslot_bad_area'
export const AREA_NOT_FOUND = 'area_not_found'
export const CART_NOT_FOUND = 'cart_not_found'
export const INVALID_SLOT = 'invalid_slot'
export const PRODUCT_PUBLICATION_NOT_FOUND = 'product_publication_not_found'
export const SHIFT_TIMESLOT_UNAVAILABLE = 'shift_timeslot_unavailable'
export const SHIFT_TIMESLOT_NOT_FOUND = 'shift_timeslot_not_found'
export const INVALID_DISCOUNT_CODE = 'invalid_discount_code'
export const CART_INVALID = 'cart_invalid'
export const HUB_NOT_FOUND = 'hub_not_found'
export const HUB_AREA_NOT_FOUND = 'hub_area_not_found'
export const HUB_SHIFT_NOT_FOUND = 'hub_shift_not_found'
export const CART_HAS_ORDER = 'cart_has_order'

export const INCORRECT_STOCK = 'incorrect_stock'
export const NOT_ENOUGH_STOCK = 'not_enough_stock'
export const PUBLICATION_CLOSED = 'publication_closed'
export const PUBLICATION_UNAVAILABLE = 'publication_unavailable'
export const SALES_BLOCKED = 'sales_blocked'
export const INVALID_SHIFT_TIMESLOT = 'invalid_shift_timeslot'
export const CODE_INVALID = 'code_invalid'
export const INVALID_QUANTITY = 'invalid_quantity'
