import { v4 as uuidv4 } from 'uuid'
import Cookie from 'js-cookie'

export const KEY = {
  security: '-O-O-',
  session: 'BOFCSID'
}

export const cookieAttributes = {
  expires: 730,
  secure: process.env.REACT_APP_ENV !== 'dev'
}

export const get = (key, burnAfterReading = false) => {
  const cookieData = Cookie.get(key)
  burnAfterReading === true && remove(key)
  return cookieData ? JSON.parse(cookieData) : null
}

export const set = (key, value) => {
  value = JSON.stringify(value)
  Cookie.set(key, value, cookieAttributes)
  return () => remove(key, cookieAttributes)
}

export const getSessionId = () => {
  let sessionId = Cookie.get(KEY.session)
  if (!sessionId) {
    sessionId = uuidv4()
    Cookie.set(
      KEY.session,
      sessionId,
      { secure: process.env.REACT_APP_ENV !== 'dev' }
    )
  }
  return sessionId
}

export const remove = (key) => {
  Cookie.remove(key, cookieAttributes, { path: '/' })
  Cookie.remove(KEY.session, cookieAttributes, { path: '/' })
  return key
}
