/* import { delay } from 'utils/misc'
import { citiesAndHubs } from 'mockData/citiesAndHubs' */
import { absoluteApiUrls, getRequestHeaders, handleError } from 'utils/api'

export const getCitiesAndHubs = async () => {
  const result = { data: null, error: null }
  const method = 'GET'
  const url = absoluteApiUrls.shop.getCitiesAndHubs
  const headers = getRequestHeaders(true)
  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = Array.isArray(responseData.data) ? responseData.data : []
    } else {
      result.error = handleError(responseData.error || responseData, response.status)
    }
  } catch (e) {
    result.error = handleError(e)
  }
  return result
}

export const updateHubPicker = async (hub) => {
  const result = { data: null, error: null }

  if (!hub) return result
  const method = 'POST'
  const url = absoluteApiUrls.shop.updateHubPicker
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    idHub: hub.idHub
  })
  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }
  return result
}
