import { showError } from './errors'
import actionTypes from './types'

const { PAGE_LOADING_START_ACTION, PAGE_LOADING_END_ACTION } = actionTypes

/**
 * returns a thunk
 * @param {Promise<Promise>} longPromiseCreatorOrPromise signature if creator: (dispatch : Function) : Promise
 */
export const simpleAction = ({ start, end, errorType }, longPromiseCreatorOrPromise) => {
  return async (dispatch, getState) => {
    dispatch({
      type: start
    })
    try {
      if (typeof longPromiseCreatorOrPromise === 'function') {
        await longPromiseCreatorOrPromise(dispatch, getState)
      } else {
        await longPromiseCreatorOrPromise
      }
    } catch (error) {
      showError({ error, type: errorType })(dispatch)
    } finally {
      dispatch({
        type: end
      })
    }

    return Promise.resolve()
  }
}
/**
 * Use to block the user from initiating another action
 * returns a thunk
 * @param {Promise<Promise>} longPromiseCreatorOrPromise signature if creator: (dispatch : Function) : Promise
 */
export const actionWithLoader = longPromiseCreatorOrPromise => {
  return async (dispatch, getState) => {
    dispatch({
      type: PAGE_LOADING_START_ACTION
    })
    try {
      if (typeof longPromiseCreatorOrPromise === 'function') {
        await longPromiseCreatorOrPromise(dispatch, getState)
      } else {
        await longPromiseCreatorOrPromise
      }
    } catch (error) {
      showError(error)(dispatch)
    } finally {
      dispatch({
        type: PAGE_LOADING_END_ACTION
      })
    }

    return Promise.resolve()
  }
}

export const onEnter = ({ store, actionThunk, getReplacingPath, withLoader = true }) => {
  return async (nextState, replace, callback) => {
    try {
      if (getReplacingPath) {
        const replacingPath = await getReplacingPath(store.getState)
        if (replacingPath) {
          replace(replacingPath)
          callback()
          return
        }
      }

      // ---- actual call ----//
      const dispatchingFunction = actionThunk(nextState.params)
      let result
      if (withLoader) {
        result = actionWithLoader(dispatchingFunction)(store.dispatch, store.getState)
      } else {
        result = dispatchingFunction(store.dispatch, store.getState)
      }
      if (result && result.then) {
        await result
      }

      callback()
    } catch (error) {
      console.error(error)
      callback(error)
    }
  }
}
