import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Router, Route } from 'react-router'

// COMPONENTS
import App from 'containers/App'
import Login from 'containers/Login'
import ShopHome from 'containers/Shop/ShopHome'
import PointOfSale from 'containers/Shop/PointOfSale'

import { onEnterApp, verifyPersistUser } from 'actions/app'
import { onEnterPointOfSale, onEnterShop } from 'actions/shop/index'

import { getUser } from 'reducers/app'

const Routes = ({ history, store }) => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)

  if (!user) {
    // use to update user if you choose to clear persist keys in localStorage
    dispatch(verifyPersistUser())
    return (
      <Login />
    )
  }

  return (
    <Router history={history}>
      <Route path='/' component={App} onEnter={onEnterApp(store)}>
        <Route path='shop' component={ShopHome} onEnter={onEnterShop(store)} />
        <Route path='point-of-sale' component={PointOfSale} onEnter={onEnterPointOfSale(store)} />
        <Route path='*' />
      </Route>
    </Router>
  )
}

export default Routes
