import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import { payByCard, selectPaymentMethod } from 'actions/shop/cart'
import CartConfirm from './CartConfirm'
import CartDetails from './CartDetails'
import CardPayment from './CardPayment'
import TRPayment from './TRPayment'
import TRValidation from './TRValidation'

import { makeStyles } from '@material-ui/core/styles'
import { getCartProducts } from 'reducers/shop'

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#F5F5F5',
    overflowX: 'hidden'
  }
})

const params = {
  details: {
    title: 'Panier',
    sort: 1
  },
  cardPayment: {
    title: 'Panier',
    sort: 2
  },
  TRPayment: {
    title: 'Saisie des titres restaurant papier',
    sort: 4
  },
  TRValidation: {
    title: 'Panier',
    sort: 5
  },
  confirm: {
    title: 'Confirmation d\'achat',
    sort: 3
  }
}

const CartPanel = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const classes = useStyles()
  const products = useSelector(getCartProducts, isEqual)
  const dispatch = useDispatch()

  const onBack = () => {
    setCurrentStep(params.details.sort)
  }
  const goToDetails = () => {
    setCurrentStep(params.details.sort)
  }
  const goToCardPayment = () => {
    dispatch(selectPaymentMethod(['CB', 'TRD']))
    setCurrentStep(params.cardPayment.sort)
    dispatch(payByCard())
  }
  const goToTRPayment = () => {
    dispatch(selectPaymentMethod(['TRP']))
    setCurrentStep(params.TRPayment.sort)
  }
  const goToConfirm = () => {
    setCurrentStep(params.confirm.sort)
  }

  return (
    <div className={clsx(classes.root)}>
      <CartDetails products={products} onNext={{ cardPayment: goToCardPayment, trPayment: goToTRPayment, confirm: goToConfirm }} step={params.details} currentStep={currentStep} />
      <CardPayment products={products} onNext={goToConfirm} onPrev={goToDetails} step={params.cardPayment} currentStep={currentStep} />
      <TRPayment onPrev={goToDetails} onNext={goToConfirm} step={params.TRPayment} currentStep={currentStep} />
      <TRValidation products={products} onNext={goToConfirm} step={params.TRValidation} currentStep={currentStep} />
      <CartConfirm onNext={onBack} step={params.confirm} currentStep={currentStep} />
    </div>
  )
}

export default CartPanel
