import { absoluteApiUrls, getRequestHeaders, handleError, getUrlFromTemplate } from 'utils/api'

export const getSections = async hub => {
  const result = { data: null, error: null }
  if (!hub) {
    result.error = { global: 'missing_parameters' }
    return result
  }
  const method = 'GET'
  const url = getUrlFromTemplate(absoluteApiUrls.shop.getProducts, { idHub: hub.idHub })
  const headers = getRequestHeaders(true)
  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = Array.isArray(responseData) ? responseData : []
    } else {
      result.error = handleError(responseData.error || responseData, response.status)
    }
  } catch (e) {
    result.error = handleError(e)
  }
  return result
}
