import { absoluteApiUrls, getUrlFromTemplate, getRequestHeaders, handleError, getUrlWithQueryParams } from 'utils/api'

export const initializeCart = async ({ idHub, withPaymentMethods }) => {
  const result = {
    data: null,
    error: null
  }

  const method = 'POST'
  let url = getUrlFromTemplate(absoluteApiUrls.shop.cart.initializeCart, { idHub })
  if (withPaymentMethods) url = getUrlWithQueryParams(url, { withPaymentMethods })
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const updateCartProducts = async ({ cartUuid, idProductPublication, quantity }) => {
  const result = {
    data: null,
    error: null
  }

  if (!cartUuid || !idProductPublication || typeof quantity !== 'number') {
    return result
  }

  const method = 'PUT'
  const url = getUrlFromTemplate(absoluteApiUrls.shop.cart.updateCartProducts, { cartUuid })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    idProductPublication,
    quantity
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const updateCartDiscounts = async (uuid, code) => {
  const result = {
    data: null,
    error: null
  }

  if (!uuid) {
    return result
  }

  const method = 'PUT'
  const url = getUrlFromTemplate(absoluteApiUrls.shop.cart.updateCartDiscounts, { uuid })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    code
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const clearCartProducts = async (uuid) => {
  const result = {
    data: null,
    error: null
  }

  if (!uuid) {
    return result
  }

  const method = 'POST'
  const url = getUrlFromTemplate(absoluteApiUrls.shop.cart.clearCartProducts, { uuid })
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const payByTRP = async (uuid, data) => {
  const result = {
    data: null,
    error: null
  }

  if (!uuid) {
    return result
  }

  const method = 'POST'
  const url = getUrlFromTemplate(absoluteApiUrls.shop.cart.payCart, { uuid })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    provider: 'user',
    withoutCard: 1,
    mealVoucherAmount: data
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const payByCard = async (uuid) => {
  const result = {
    data: null,
    error: null
  }

  if (!uuid) {
    return result
  }
  const method = 'POST'
  const url = getUrlFromTemplate(absoluteApiUrls.shop.cart.payCart, { uuid })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    provider: 'yavin'
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const payWithDiscounts = async (uuid) => {
  const result = {
    data: null,
    error: null
  }

  if (!uuid) {
    return result
  }

  const method = 'POST'
  const url = getUrlFromTemplate(absoluteApiUrls.shop.cart.payCart, { uuid })
  const headers = getRequestHeaders(true)
  const body = JSON.stringify({
    provider: 'user',
    withoutCard: 1
  })

  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const confirmPayment = async (hashOrder) => {
  const result = {
    data: null,
    error: null
  }

  if (!hashOrder) {
    return result
  }

  const method = 'POST'
  const url = getUrlFromTemplate(absoluteApiUrls.shop.cart.confirmTransaction, { provider: 'yavin', hashOrder })
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const resetCartOrder = async (cartUuid) => {
  const result = {
    data: null,
    error: null
  }

  if (!cartUuid) {
    return result
  }

  const method = 'GET'
  const url = getUrlFromTemplate(absoluteApiUrls.shop.cart.resetCartOrder, { cartUuid })
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const assignCartToCustomer = async (uuid, customerId) => {
  const result = {
    data: null,
    error: null
  }

  if (!uuid) {
    return result
  }

  const method = 'POST'
  const url = getUrlFromTemplate(absoluteApiUrls.shop.cart.assignCartToCustomer, { uuid, customerId })
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const unassignCart = async (uuid) => {
  const result = {
    data: null,
    error: null
  }

  if (!uuid) {
    return result
  }

  const method = 'POST'
  const url = getUrlFromTemplate(absoluteApiUrls.shop.cart.unassignCart, { uuid })
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const getCart = async (uuid) => {
  const result = {
    data: null,
    error: null
  }

  if (!uuid) {
    return result
  }

  const method = 'GET'
  const url = getUrlFromTemplate(absoluteApiUrls.shop.cart.getCart, { uuid })
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = {
        data: responseData.error || responseData,
        status: response.status
      }
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}
