import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/Check'

import CartHeader from 'components/shop/CartHeader'
import CustomerIdentifier from 'components/shop/CustomerIdentifier'
import ExpensesSummary from 'components/shop/ExpensesSummary'
import OrderSummary from 'components/shop/OrderSummary'

import { clearCart, selectPaymentMethod } from 'actions/shop/cart'
import { clearIdentifiedCustomer } from 'actions/shop/customers'
import { getCustomer, getTotalDiscount } from 'reducers/shop'

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap'
  },
  body: {
    flexGrow: 1,
    overflow: 'auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center'
  },
  paymentConfirmation: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    margin: '40px 0'
  },
  confirmationTitle: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '17px',
    letterSpacing: '0.25px',
    '& > div': {
      padding: 15
    }
  },
  confirmationMessage: {
    padding: 10
  },
  confirmationEmail: {
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'center',
    letterSpacing: '0.25px',
    color: '#9B9B9B',
    padding: 20
  },
  footer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    marginTop: 'auto'
  },
  button: {
    margin: 15
  },
  checkIcon: {
    color: '#2CC9AA'
  }
})

const CartConfirm = ({ onNext, step, currentStep }) => {
  const { title, sort } = step
  const dispatch = useDispatch()
  const identifiedCustomer = useSelector(getCustomer)
  const totalDiscount = useSelector(getTotalDiscount)
  const classes = useStyles()

  if (currentStep !== sort) return null

  const handleSubmit = async () => {
    if (identifiedCustomer) await dispatch(clearIdentifiedCustomer())
    await dispatch(clearCart(true))
    dispatch(selectPaymentMethod())
    onNext()
  }
  return (
    <div className={classes.root}>
      <CartHeader title={title} />
      <div className={classes.body}>
        <div className={classes.paymentConfirmation}>
          <div className={classes.confirmationTitle}>
            <CheckIcon className={classes.checkIcon} />
            <div>Paiement réussi</div>
          </div>
          {identifiedCustomer
            ? (
              <>
                <div className={classes.confirmationMessage}>Vous pouvez remettre la commande à</div>
                <CustomerIdentifier canRemoveCustomer={false} />
              </>)
            : (
              <div className={classes.confirmationMessage}>Vous pouvez remettre la commande au Client.</div>)}
        </div>
        <ExpensesSummary showDiscountInfo={totalDiscount > 0} />
        <OrderSummary />
      </div>
      <div className={classes.footer}>
        <div className={classes.confirmationEmail}>
          Un e-mail de confirmation a été envoyé.
        </div>
        <Button variant='outlined' color='primary' className={classes.button} onClick={handleSubmit}>Nouvelle commande</Button>
      </div>
    </div>
  )
}

export default CartConfirm
