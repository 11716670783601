import actionTypes from './types'
import { onEnter } from './utils'
import * as cookie from 'utils/cookies'
import { logout } from './auth'
import { getMe } from './users'

export const updateDevice = (device) => {
  return async dispatch => {
    dispatch({
      type: actionTypes.SET_DEVICE_TYPE_ACTION,
      device
    })
  }
}

export const onEnterApp = (store) => {
  return onEnter({
    store,
    actionThunk: () => {
      return async (dispatch) => {
        const secCookie = cookie.get(cookie.KEY.security)
        if (secCookie) {
          dispatch(getMe({ needToUpdate: false }))
        } else {
          dispatch(logout())
        }
      }
    }
  })
}

export const verifyPersistUser = () => {
  return async (dispatch) => {
    const secCookie = cookie.get(cookie.KEY.security)
    if (secCookie) {
      dispatch(getMe({ needToUpdate: true }))
    }
  }
}
