import qs from 'qs'
import URLS from 'api/urls.json'
import * as cookie from 'utils/cookies'

const defaultHeaders = {
  Accept: 'application/json, application/xml, text/plain, text/html, *.*',
  'Content-Type': 'application/json; charset=utf-8',
  'x-fc-api-key': process.env.REACT_APP_FC_API_KEY
}
export const getRequestHeaders = (authenticated = true) => {
  const token = authenticated ? cookie.get(cookie.KEY.security) : null

  const headers = {
    ...defaultHeaders,
    'x-session-id': cookie.getSessionId(),
    'x-timestamp': Date.now()
  }
  if (token) {
    headers.Authorization = 'Token ' + token
  } else if (!token && authenticated) {
    // handle the fact that we redirect the user if he tries to call the backend without behing authenticated
    window.location.replace('/')
  }

  return headers
}

export const getAbsoluteUrls = (host, urls) => {
  host = host || ''
  const absoluteUrls = {}
  Object
    .entries(urls)
    .forEach(([key, url]) => {
      if (url && typeof url === 'string') {
        url = `${url[0] === '/' ? '' : '/'}${url}`
        absoluteUrls[key] = `${host}${url}`
      } else if (typeof url === 'object' && !Array.isArray(url)) {
        absoluteUrls[key] = { ...getAbsoluteUrls(host, url) }
      }
    })
  return absoluteUrls
}
export const absoluteApiUrls = getAbsoluteUrls(process.env.REACT_APP_API_HOST, URLS.api)

export const handleError = (error, status) => {
  switch (status) {
    case 404:
      return { message: 'not_found', code: status }
    case 401:
      return { message: 'unauthorized_request', code: status }
    case 403:
      return { message: 'forbidden_request', code: status }
    case 400:
      return { message: 'bad_request', code: status }
    case 406:
      return { message: 'not_acceptable', code: status, type: error.type }
    case 500:
      return { message: 'server_error', code: status }
    default:
      return { message: 'unknown_error', code: status || null }
  }
}

export const getUrlWithQueryParams = (url = '', params = {}) => (
  `${url}${params && Object.keys(params).length > 0 ? '?' : ''}${qs.stringify(params)}`
)

export const getUrlFromTemplate = (url = '', params = {}) => {
  Object
    .entries(params)
    .forEach(
      ([key, param]) => {
        url = url.replace(`{${key}}`, param || '')
      }
    )
  return url.replace((/\/$/), '')
}
