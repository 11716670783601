import actionTypes from 'actions/types'
import {
  simpleAction
} from '../utils'
import * as api from 'api/users'
import * as apiCart from 'api/cart'
import { getErrorMessageFr, showError } from '../errors'
import { getCartUUID, getCustomerError, getCustomersOptions, getSelectedHub, getShopInfoMessage } from 'reducers/shop'
import { isEmpty } from 'lodash'
import { ERROR_IDENTIFY_CUSTOMER_BY_ID, ERROR_ASSIGN_CART_TO_CUSTOMER, ERROR_IDENTIFY_CUSTORMER_BY_PHONE, GENERIC_ERROR, GENERIC_ERROR_MESSAGE } from 'utils/errors'
import { initializeCart, handleNonBlockingCartErrors, reloadCart } from './cart'

export const identifyCustomer = ({ id = null, customer = null }) => {
  return simpleAction({ start: actionTypes.IDENTIFY_CUSTOMER_LOADING_START_ACTION, end: actionTypes.IDENTIFY_CUSTOMER_LOADING_END_ACTION, errorType: actionTypes.IDENTIFY_CUSTOMER_ERROR_ACTION }, async (dispatch, getState) => {
    try {
      let usableCustomer = customer
      if (!id && !customer) {
        throw new Error(ERROR_IDENTIFY_CUSTOMER_BY_ID)
      }
      if (id) {
        const result = await api.getUserById(id)
        if (result.error) {
          throw new Error(ERROR_IDENTIFY_CUSTOMER_BY_ID)
        }
        usableCustomer = result.data.customers[0]
      }
      const cartUUID = getCartUUID(getState())

      const response = await apiCart.assignCartToCustomer(cartUUID, usableCustomer.id)
      const { data, error } = response
      if (error || data === null) {
        throw new Error(ERROR_ASSIGN_CART_TO_CUSTOMER)
      }
      const result = await apiCart.getCart(cartUUID)
      if (result.error || result.data === null) {
        const selectedHub = getSelectedHub(getState())
        dispatch(initializeCart(selectedHub.idHub))
        throw new Error(GENERIC_ERROR)
      }
      const { cart, status } = result.data || {}
      if (result.data?.status?.errors) {
        dispatch({
          type: actionTypes.RELOAD_CART_NON_BLOCKING_ERRORS_ACTION,
          cart,
          status
        })
        await dispatch(handleNonBlockingCartErrors(data?.status?.errors, data?.status?.valid))
        return
      }
      await dispatch({
        type: actionTypes.RELOAD_CART_SUCCESS_ACTION,
        cart,
        status
      })
      dispatch({
        type: actionTypes.IDENTIFY_CUSTOMER_ACTION,
        customer: usableCustomer
      })
      const customerError = getCustomerError(getState())
      if (customerError) dispatch({ type: actionTypes.CLEAR_CUSTOMER_ERROR })
    } catch (error) {
      dispatch(showError({ error: getErrorMessageFr(error), type: actionTypes.IDENTIFY_CUSTOMER_ERROR_ACTION }))
    }
  })
}

export const disIdentifyCustomer = () => {
  return simpleAction({ start: actionTypes.DISIDENTIFY_CUSTOMER_LOADING_START_ACTION, end: actionTypes.DISIDENTIFY_CUSTOMER_LOADING_END_ACTION, errorType: actionTypes.DISIDENTIFY_CUSTOMER_ERROR_ACTION }, async (dispatch, getState) => {
    try {
      const cartUUID = getCartUUID(getState())
      const response = await apiCart.unassignCart(cartUUID)
      const { data, error } = response
      if (error || data === null) {
        throw new Error('Failed to dis-identify customer')
      }
      await dispatch(reloadCart())
      const infoMessage = getShopInfoMessage(getState())
      if (!infoMessage) {
        dispatch({
          type: actionTypes.DISIDENTIFY_CUSTOMER_ACTION
        })
      }
    } catch (error) {
      dispatch({ type: actionTypes.DISIDENTIFY_CUSTOMER_ERROR_ACTION, content: GENERIC_ERROR_MESSAGE })
    }
  })
}

export const clearCustomerErrors = () => dispatch => {
  dispatch({ type: actionTypes.CLEAR_CUSTOMER_ERROR })
}

export const clearIdentifiedCustomer = () => dispatch => {
  dispatch({ type: actionTypes.DISIDENTIFY_CUSTOMER_ACTION })
}

export const getCustomersByPhone = (phone) => {
  return simpleAction({ start: actionTypes.GET_CUSTOMERS_OPTIONS_LOADING_START_ACTION, end: actionTypes.GET_CUSTOMERS_OPTIONS_LOADING_END_ACTION, errorType: actionTypes.GET_CUSTOMERS_OPTIONS_ERROR_ACTION }, async (dispatch, getState) => {
    try {
      const result = await api.getUserByPhone(phone)
      if (result.error) {
        if (result.error.message === 'bad_request') throw new Error(ERROR_IDENTIFY_CUSTORMER_BY_PHONE)
        else throw new Error(GENERIC_ERROR)
      } else {
        dispatch({
          type: actionTypes.GET_CUSTOMERS_OPTIONS_ACTION,
          options: result.data.customers
        })
        const customerError = getCustomerError(getState())
        if (customerError) dispatch({ type: actionTypes.CLEAR_CUSTOMER_ERROR })
      }
    } catch (error) {
      dispatch(showError({ error: getErrorMessageFr(error), type: actionTypes.GET_CUSTOMERS_OPTIONS_ERROR_ACTION }))
      const customerOptions = getCustomersOptions(getState())
      if (!isEmpty(customerOptions)) dispatch({ type: actionTypes.CLEAR_CUSTOMERS_OPTIONS_ACTION })
    }
  })
}

export const clearCustomersOptions = () => dispatch => {
  dispatch({ type: actionTypes.CLEAR_CUSTOMERS_OPTIONS_ACTION })
}
