import { ERROR_CONNECTION, ERROR_LOGIN } from 'utils/errors'

export const showError = ({ error, type }) => {
  return (dispatch) => {
    dispatch({ type, error })
  }
}

export const getErrorMessageFr = (error) => {
  if (!error || typeof error === 'string') return error
  switch (error.code) {
    case 100:
      return ERROR_CONNECTION
    case 101:
      return ERROR_LOGIN
    default:
      return error.message
  }
}
