import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles({
  root: {
    width: 582,
    padding: [[20, 34]],
    backgroundColor: '#ffffff'
  },
  header: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    marginBottom: 30
  },
  headerTitle: {
    textTransform: 'uppercase',
    fontWeight: 700,
    color: '#AFAFAF',
    fontSize: 17,
    lineHeight: '20.4px'
  },
  closeIcon: {
    alignSelf: 'flex-start',
    opacity: '0.54',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  title: {
    letterSpacing: '0.15px',
    fontSize: '20px',
    lineHeight: '24px',
    fontWeight: 500,
    marginBottom: 30
  },
  paragraph: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.54)'
  },
  horizontalRow: {
    backgroundColor: '#C4C4C4',
    height: '1px',
    border: 0,
    margin: [[30, 0]]
  }
})

const DepositDetails = ({ onClose = () => {} }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>Discours client</div>
        <CloseIcon onClick={onClose} className={classes.closeIcon} />
      </div>
      <div className={classes.title}>
        Qu'est-ce que la consigne ?
      </div>
      <div className={classes.paragraph}>
        Un emballage réutilisable qui vous permet de contribuer au développement durable.
      </div>
      <hr className={classes.horizontalRow} />
      <div className={classes.title}>
        Comment ça marche ?
      </div>
      <div className={classes.paragraph}>
        Aujourd’hui en choisissant un plat qui est consigné, vous investissez 2€ qui vous seront recrédités une fois que votre emballage sera remis à la borne GreenGo. Cet avantage sera disponible soit sur le site FoodChéri par le biais d’une identification, soit grâce à un code promotionnel directement imprimé depuis la borne. Vous pourrez donc en profiter en magasin ou sur internet.
      </div>
    </div>
  )
}

export default DepositDetails
