import actionTypes from 'actions/types'
import * as api from 'api/cart'
import * as cartErrors from 'api/cartErrors'
import { simpleAction } from '../utils'
import { GENERIC_ERROR_MESSAGE, ERROR_UNKNOWN_PAYMENT, ERROR_CART_DISCOUNT_INVALID, ERROR_CANCELLED_PAYMENT, ERROR_MISSING_PARAMETERS } from 'utils/errors'
import { showError, getErrorMessageFr } from 'actions/errors'
import { getCartUUID, getSelectedHub, getIsCancelled } from 'reducers/shop'
import { getSections } from './sections'

const SUCCESS_STATUT_PAYMENT = 'DONE'
const ERROR_STATUT_PAYMENT = 'PAYMENT_ERROR'
const PENDING_STATUT_PAYMENT = 'PENDING_PAYMENT_VALIDATION'

export const initializeCart = ({ idHub, withPaymentMethods = false }) => {
  return simpleAction({ start: actionTypes.INITIALIZE_CART_LOADING_START_ACTION, end: actionTypes.INITIALIZE_CART_LOADING_END_ACTION, errorType: actionTypes.INITIALIZE_CART_ERROR_ACTION }, async (dispatch) => {
    const { data, error } = await api.initializeCart({ idHub, withPaymentMethods })
    if (error) {
      await dispatch(handleBlockingCartError(error, actionTypes.INITIALIZE_CART_ERROR_ACTION))
      return
    }
    if (data?.status?.errors) {
      await dispatch({
        type: actionTypes.INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION,
        ...data
      })
      await dispatch(handleNonBlockingCartErrors(data?.status?.errors, data?.status?.valid))
      return
    }
    await dispatch({
      type: actionTypes.INITIALIZE_CART_SUCCESS_ACTION,
      ...data
    })
  })
}

export const updateCartProducts = ({ cartUuid, idProductPublication, quantity }) => {
  return simpleAction({ start: actionTypes.UPDATE_CART_PRODUCTS_LOADING_START_ACTION, end: actionTypes.UPDATE_CART_PRODUCTS_LOADING_END_ACTION, errorType: actionTypes.UPDATE_CART_PRODUCTS_ERROR_ACTION }, async (dispatch, getState) => {
    const { data, error } = await api.updateCartProducts({ cartUuid, idProductPublication, quantity })
    if (error) {
      await dispatch(handleBlockingCartError(error, actionTypes.UPDATE_CART_PRODUCTS_ERROR_ACTION))
      return
    }
    if (data?.status?.errors) {
      await dispatch({
        type: actionTypes.UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION,
        ...data
      })
      await dispatch(handleNonBlockingCartErrors(data?.status?.errors, data?.status?.valid))
      return
    }
    await dispatch({
      type: actionTypes.UPDATE_CART_PRODUCTS_SUCCESS_ACTION,
      ...data
    })
  })
}

export const clearCartProducts = () => {
  return simpleAction({ start: actionTypes.CLEAR_CART_PRODUCTS_LOADING_START_ACTION, end: actionTypes.CLEAR_CART_PRODUCTS_LOADING_END_ACTION, errorType: actionTypes.CLEAR_CART_PRODUCTS_ERROR_ACTION }, async (dispatch, getState) => {
    const uuid = getCartUUID(getState())
    const { error, data } = await api.clearCartProducts(uuid)
    if (error || !data) {
      await dispatch(handleBlockingCartError(error, actionTypes.CLEAR_CART_PRODUCTS_ERROR_ACTION))
    } else {
      await dispatch(reloadCart())
    }
  })
}

export const reloadCart = () => {
  return simpleAction({ start: actionTypes.RELOAD_CART_LOADING_START_ACTION, end: actionTypes.RELOAD_CART_LOADING_END_ACTION, errorType: actionTypes.RELOAD_CART_ERROR_ACTION }, async (dispatch, getState) => {
    const uuid = getCartUUID(getState())
    const { data, error } = await api.getCart(uuid)
    if (error || data === null) {
      await dispatch(handleBlockingCartError(error, actionTypes.RELOAD_CART_ERROR_ACTION))
      return
    }
    if (data?.status?.errors) {
      dispatch({
        type: actionTypes.RELOAD_CART_NON_BLOCKING_ERRORS_ACTION,
        ...data
      })
      await dispatch(handleNonBlockingCartErrors(data?.status?.errors, data?.status?.valid))
      return
    }
    await dispatch({
      type: actionTypes.RELOAD_CART_SUCCESS_ACTION,
      ...data
    })
  })
}

export const updateCartDiscounts = code => {
  return simpleAction({ start: actionTypes.UPDATE_CART_DISCOUNTS_LOADING_START_ACTION, end: actionTypes.UPDATE_CART_DISCOUNTS_LOADING_END_ACTION, errorType: actionTypes.UPDATE_CART_DISCOUNTS_ERROR_ACTION }, async (dispatch, getState) => {
    const uuid = getCartUUID(getState())
    const { data, error } = await api.updateCartDiscounts(uuid, code)
    if (error || !data) {
      await dispatch(handleBlockingCartError(error, actionTypes.UPDATE_CART_DISCOUNTS_ERROR_ACTION))
      return
    }
    if (data?.status?.errors) {
      dispatch({
        type: actionTypes.UPDATE_CART_DISCOUNTS_NON_BLOCKING_ERRORS_ACTION,
        ...data
      })
      await dispatch(handleNonBlockingCartErrors(data?.status?.errors, data?.status?.valid))
      return
    }
    await dispatch({
      type: actionTypes.UPDATE_CART_DISCOUNTS_SUCCESS_ACTION,
      ...data
    })
    await dispatch(clearCartDiscountsError())
  })
}

const handleBlockingCartError = (error, errorAction) => async (dispatch) => {
  // allow to display modal error
  if (!error) dispatch({ type: actionTypes.SET_SHOP_INFO_MESSAGE_ACTION, content: GENERIC_ERROR_MESSAGE })
  if (error?.data) {
    const { type = null } = error.data
    switch (type) {
      case cartErrors.CART_BAD_CUSTOMER:
      case cartErrors.INVALID_PARAMETER:
      case cartErrors.SHIFT_TIMESLOT_BAD_AREA:
      case cartErrors.AREA_NOT_FOUND:
      case cartErrors.CART_NOT_FOUND:
      case cartErrors.INVALID_SLOT:
      case cartErrors.PRODUCT_PUBLICATION_NOT_FOUND:
      case cartErrors.SHIFT_TIMESLOT_UNAVAILABLE:
      case cartErrors.SHIFT_TIMESLOT_NOT_FOUND:
      case cartErrors.CART_INVALID:
      case cartErrors.CART_HAS_ORDER:
      case cartErrors.HUB_NOT_FOUND:
      case cartErrors.HUB_AREA_NOT_FOUND:
      case cartErrors.HUB_SHIFT_NOT_FOUND:
        await dispatch(showError({ type: errorAction, error }))
        dispatch({ type: actionTypes.SET_SHOP_INFO_MESSAGE_ACTION, content: GENERIC_ERROR_MESSAGE })
        break
      case cartErrors.NOT_FOUND: // handle route not found error
        await dispatch(showError({ type: errorAction, error: 'Failed to found this route' }))
        dispatch({ type: actionTypes.SET_SHOP_INFO_MESSAGE_ACTION, content: GENERIC_ERROR_MESSAGE })
        break
      case cartErrors.INVALID_DISCOUNT_CODE:
        await dispatch(showError({ type: errorAction, error: ERROR_CART_DISCOUNT_INVALID }))
        break
    }
  }
}

export const handleNonBlockingCartErrors = (errors = [], isCartValid = true) => async (dispatch, getState) => {
  for (const { code } of errors) {
    switch (code) {
      case cartErrors.PUBLICATION_CLOSED:
      case cartErrors.PUBLICATION_UNAVAILABLE:
      case cartErrors.INVALID_SHIFT_TIMESLOT:
        if (!isCartValid || cartErrors.INVALID_SHIFT_TIMESLOT) {
          const selectedHub = getSelectedHub(getState())
          await dispatch(initializeCart({ idHub: selectedHub.idHub, withPaymentMethods: true }))
          await dispatch(getSections(selectedHub))
        }
        break
      case cartErrors.INCORRECT_STOCK:
      case cartErrors.NOT_ENOUGH_STOCK:
      case cartErrors.SALES_BLOCKED:
      case cartErrors.CODE_INVALID:
      case cartErrors.INVALID_QUANTITY:
        break
      default:
        break
    }
  }
}

export const payByTRP = (data, onSuccess) => {
  return simpleAction({ start: actionTypes.SET_CHECKOUT_LOADING_START_ACTION, end: actionTypes.SET_CHECKOUT_LOADING_END_ACTION, errorType: actionTypes.SET_CHECKOUT_ERROR_ACTION }, async (dispatch, getState) => {
    try {
      const uuid = getCartUUID(getState())
      const paymentResponse = await api.payByTRP(uuid, data)
      const { data: paymentData, error: paymentError } = paymentResponse
      if (!paymentData && !paymentError) throw new Error(ERROR_UNKNOWN_PAYMENT)
      if (paymentData?.status === SUCCESS_STATUT_PAYMENT) {
        onSuccess && onSuccess()
      } else if (paymentError) {
        throw new Error(paymentError.data?.message || ERROR_UNKNOWN_PAYMENT)
      }
    } catch (error) {
      if (['not_acceptable', 'not_found'].includes(error.message)) error.message = ERROR_UNKNOWN_PAYMENT
      dispatch(showError({ error: getErrorMessageFr(error), type: actionTypes.SET_CHECKOUT_ERROR_ACTION }))
    }
  })
}

export const payByCard = () => {
  return simpleAction({ start: actionTypes.SET_CHECKOUT_LOADING_START_ACTION, end: actionTypes.SET_CHECKOUT_LOADING_END_ACTION, errorType: actionTypes.SET_CHECKOUT_ERROR_ACTION }, async (dispatch, getState) => {
    try {
      const uuid = getCartUUID(getState())
      const paymentResponse = await api.payByCard(uuid)
      const { data: paymentData, error: paymentError } = paymentResponse
      if (!paymentData && !paymentError) throw new Error(ERROR_MISSING_PARAMETERS)
      if (paymentData?.status === SUCCESS_STATUT_PAYMENT || paymentData?.status === PENDING_STATUT_PAYMENT) {
        dispatch({
          type: actionTypes.PAYMENT_PROCESSING_ACTION,
          hashOrder: paymentData.hashOrder,
          isLoading: true
        })
      } else if (paymentError) {
        throw new Error(paymentError.data?.message || ERROR_UNKNOWN_PAYMENT)
      }
    } catch (error) {
      if (['not_acceptable', 'not_found'].includes(error.message)) error.message = ERROR_UNKNOWN_PAYMENT
      dispatch(showError({ error: getErrorMessageFr(error), type: actionTypes.SET_CHECKOUT_ERROR_ACTION }))
    }
  })
}

export const payWithDiscounts = ({ onSuccess }) => {
  return simpleAction({ start: actionTypes.SET_CHECKOUT_LOADING_START_ACTION, end: actionTypes.SET_CHECKOUT_LOADING_END_ACTION, errorType: actionTypes.SET_CHECKOUT_ERROR_ACTION }, async (dispatch, getState) => {
    try {
      const uuid = getCartUUID(getState())
      const paymentResponse = await api.payWithDiscounts(uuid)
      const { data: paymentData, error: paymentError } = paymentResponse
      if (!paymentData && !paymentError) throw new Error(ERROR_MISSING_PARAMETERS)
      if (paymentData?.status === SUCCESS_STATUT_PAYMENT) {
        await dispatch({ type: actionTypes.CONFIRM_PAYMENT_ACTION_SUCCESS, hashOrder: null, isLoading: false })
        onSuccess()
      } else if (paymentError) {
        throw new Error(paymentError.data?.message || ERROR_UNKNOWN_PAYMENT)
      }
    } catch (error) {
      if (['not_acceptable', 'not_found'].includes(error.message)) error.message = ERROR_UNKNOWN_PAYMENT
      dispatch(showError({ error: getErrorMessageFr(error), type: actionTypes.SET_CHECKOUT_ERROR_ACTION }))
    }
  })
}

export const confirmPayment = (hashOrder, onSuccess) => async (dispatch, getState) => {
  try {
    const isCancelled = getIsCancelled(getState())
    const response = await api.confirmPayment(hashOrder)
    const { data, error } = response
    if (!data && !error) throw new Error(ERROR_MISSING_PARAMETERS)
    if (data) {
      if (data?.status === SUCCESS_STATUT_PAYMENT) {
        await dispatch({ type: actionTypes.CONFIRM_PAYMENT_ACTION_SUCCESS, hashOrder: null, isLoading: false })
        onSuccess()
      } else if (data?.status === PENDING_STATUT_PAYMENT) {
        if (isCancelled) {
          throw new Error(ERROR_CANCELLED_PAYMENT)
        }
        return
      } else if (data?.status === ERROR_STATUT_PAYMENT) {
        throw new Error(ERROR_UNKNOWN_PAYMENT)
      }
    } else if (error) {
      throw new Error(error.data?.message || ERROR_UNKNOWN_PAYMENT)
    }
  } catch (error) {
    dispatch(showError({ error: getErrorMessageFr(error), type: actionTypes.CONFIRM_PAYMENT_ACTION_ERROR }))
  }
}

export const cancelPayment = () => dispatch => {
  dispatch({
    type: actionTypes.CANCEL_PAYMENT_ACTION
  })
}

export const selectPaymentMethod = (paymentMethod = []) => dispatch => {
  dispatch({
    type: actionTypes.SELECT_PAYMENT_METHOD,
    selected: paymentMethod
  })
}

export const setPaymentWaitingAuthorization = (action = false) => (dispatch) => {
  dispatch({
    type: actionTypes.PAYMENT_WAITING_ACTION,
    action: action
  })
}

export const clearCheckoutError = () => dispatch => {
  dispatch({
    type: actionTypes.CLEAR_CHECKOUT_ERROR
  })
}

export const clearCartDiscountsError = () => dispatch => {
  dispatch({ type: actionTypes.CLEAR_CART_DISCOUNTS_ERROR })
}

export const clearCart = (shouldReset = true) => (dispatch, getState) => {
  const selectedHub = getSelectedHub(getState())
  dispatch({
    type: actionTypes.CLEAR_CART_ACTION
  })
  if (shouldReset) {
    dispatch(initializeCart({ idHub: selectedHub.idHub, withPaymentMethods: true }))
    dispatch(getSections(selectedHub))
  }
}

export const resetCartOrder = () => async (dispatch, getState) => {
  try {
    const uuid = getCartUUID(getState())
    const response = await api.resetCartOrder(uuid)
    const { data, error } = response
    if (!data && !error) throw new Error(ERROR_MISSING_PARAMETERS)
    if (error) {
      await dispatch(handleBlockingCartError(error, actionTypes.RESET_CART_ORDER_ACTION_ERROR))
      return
    }
    if (data?.status?.errors) {
      await dispatch({
        type: actionTypes.RESET_CART_ORDER_NON_BLOCKING_ERRORS_ACTION,
        ...data
      })
      await dispatch(handleNonBlockingCartErrors(data?.status?.errors, data?.status?.valid))
      return
    }
  } catch (error) {
    dispatch(showError({ error: getErrorMessageFr(error), type: actionTypes.RESET_CART_ORDER_ACTION_ERROR }))
  }
}
