import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import TableFooter from '@material-ui/core/TableFooter'
import Paper from '@material-ui/core/Paper'
import CloseIcon from '@material-ui/icons/Close'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputAdornment from '@material-ui/core/InputAdornment'
import CancelIcon from '@material-ui/icons/Cancel'

import { getCompanyDiscount, getCompanySetting, getCustomerCredit, getTotalDiscount, getCartError, getCartLoading, getCompanyOffer, getCompanyOfferTotalAmount, getCoupon } from 'reducers/shop'
import { updateCartDiscounts } from 'actions/shop/cart'

import { ERROR_CART_DISCOUNT_INVALID } from 'utils/errors'
import { getEuroLabel, getPercentageLabel } from 'utils/text'

const useStyles = makeStyles({
  root: {
    width: 600,
    padding: '30px 23px',
    backgroundColor: '#ffffff'
  },
  header: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    height: 40,
    marginBottom: 40
  },
  title: {
    letterSpacing: '0.15px',
    fontSize: '20px',
    fontWeight: 500,
    alignSelf: 'flex-end'
  },
  closeIcon: {
    alignSelf: 'flex-start',
    opacity: '0.54',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  discountRow: {
    width: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    alignItems: 'center'
  },
  discountTextField: {
    width: 320,
    '& .MuiFormLabel-root.Mui-disabled': {
      color: ({ error, coupon }) => error && !coupon ? '#BF0000' : coupon ? '#2CC9AA' : ''
    },
    '& .MuiInput-underline:before': {
      borderBottom: ({ error, coupon }) => `1px solid ${error && !coupon ? '#BF0000' : coupon ? '#2CC9AA' : ''}`
    }
  },
  cancelIcon: {
    cursor: 'pointer'
  },
  discountValidationButton: {
    textTransform: 'uppercase',
    margin: 10,
    marginLeft: '20px'
  },
  discount: {
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '24px'
  },
  discountDetails: {
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '24px'
  }
})

const DiscountDetails = ({ onClose = () => {}, showCoupon = false }) => {
  const dispatch = useDispatch()
  const companyDiscount = useSelector(getCompanyDiscount)
  const companyName = useSelector(getCompanySetting)
  const customerCredit = useSelector(getCustomerCredit)
  let error = useSelector(getCartError)
  const isCartLoading = useSelector(getCartLoading)
  error = error !== ERROR_CART_DISCOUNT_INVALID ? null : error
  const companyOfferAmount = useSelector(getCompanyOfferTotalAmount)
  const companyOffer = useSelector(getCompanyOffer)
  const coupon = useSelector(getCoupon)
  const [discountCodeInputValue, setDiscountCodeInputValue] = useState(coupon?.code || '')
  const totalDiscount = useSelector(getTotalDiscount)

  const offerTitle = (
    companyOffer
      ? `${
        companyOffer?.title || ''
      } - ${
        companyOffer.discountType === 'PERCENTAGE'
          ? getPercentageLabel(companyOffer.value)
          : getEuroLabel({ count: parseInt(companyOffer.value) })
      }`
      : ''
  )

  function applyDiscountCode () {
    dispatch(updateCartDiscounts(discountCodeInputValue))
  }

  function removeDiscountCode () {
    dispatch(updateCartDiscounts(null))
    setDiscountCodeInputValue('')
  }
  const handleClose = () => {
    onClose()
    if (!coupon && error) dispatch(updateCartDiscounts(null))
  }
  const classes = useStyles({ error, coupon })
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>
          Détail des remises appliquées ({getEuroLabel({ count: totalDiscount })})
        </div>
        <CloseIcon onClick={handleClose} className={classes.closeIcon} />
      </div>
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableBody>
            {(companyDiscount !== 0 && companyName) && (
              <TableRow>
                <TableCell className={classes.discount} component='th' scope='row'>
                  Subvention
                </TableCell>
                <TableCell className={classes.discountDetails} align='left'>{companyName}</TableCell>
                <TableCell className={classes.discount} align='right'>{getEuroLabel({ count: companyDiscount })}</TableCell>
              </TableRow>
            )}
            {companyOffer && (
              <TableRow>
                <TableCell className={classes.discount} component='th' scope='row'>
                  Réduction offre entreprise
                </TableCell>
                <TableCell className={classes.discountDetails} align='left'>{offerTitle}</TableCell>
                <TableCell className={classes.discount} align='right'>{companyOfferAmount > 0 ? getEuroLabel({ count: companyOfferAmount }) : 0}</TableCell>
              </TableRow>
            )}
            {customerCredit !== 0 && (
              <TableRow>
                <TableCell className={classes.discount} component='th' scope='row'>
                  Cagnotte
                </TableCell>
                <TableCell />
                <TableCell className={classes.discount} align='right'>{getEuroLabel({ count: customerCredit })}</TableCell>
              </TableRow>
            )}
            {coupon && (
              <TableRow>
                <TableCell className={classes.discount} component='th' scope='row'>
                  Code promo
                </TableCell>
                <TableCell className={classes.discountDetails} align='left'>{discountCodeInputValue}</TableCell>
                <TableCell className={classes.discount} align='right'>{getEuroLabel({ count: coupon.discountAmount })}</TableCell>
              </TableRow>
            )}
          </TableBody>
          {showCoupon && (
            <TableFooter>
              <TableRow>
                <TableCell colspan={3}>
                  <div className={classes.discountRow}>
                    <TextField
                      className={classes.discountTextField}
                      id='discount-code'
                      label={isCartLoading
                        ? ''
                        : coupon && !error
                          ? 'Remise effectuée'
                          : !coupon && error ? error : 'Appliquer une remise supplémentaire'}
                      value={discountCodeInputValue}
                      onChange={(e) => setDiscountCodeInputValue(e.target.value)}
                      disabled={error || coupon}
                      InputProps={{
                        endAdornment: (coupon || error) && (
                          <InputAdornment position='end'>
                            <CancelIcon className={classes.cancelIcon} onClick={removeDiscountCode} />
                          </InputAdornment>
                        )
                      }}
                    />
                    {!coupon && !error && (
                      <Button
                        className={classes.discountValidationButton}
                        variant='contained'
                        color='primary'
                        disabled={error}
                        onClick={applyDiscountCode}
                      >
                        Valider le code
                      </Button>
                    )}
                  </div>
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </div>
  )
}

export default DiscountDetails
