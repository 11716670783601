import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

const LoginForm = ({ classes, formik }) => {
  const { handleSubmit, handleChange, touched, errors, values } = formik

  return (
    <form onSubmit={handleSubmit} className={classes.form} noValidate>
      <TextField
        className={classes.textfield}
        variant='outlined'
        margin='normal'
        required
        fullWidth
        id='email'
        label='Adresse mail'
        name='email'
        autoComplete='email'
        autoFocus
        value={values.email}
        onChange={handleChange}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
      />
      <TextField
        className={classes.textfield}
        variant='outlined'
        margin='normal'
        required
        fullWidth
        name='password'
        label='Mot de passe'
        type='password'
        id='password'
        autoComplete='current-password'
        onChange={handleChange}
        error={touched.password && Boolean(errors.password)}
        helperText={touched.password && errors.password}
      />
      <Button
        type='submit'
        fullWidth
        variant='contained'
        className={classes.submit}
      >
        Se connecter
      </Button>
    </form>
  )
}

export default LoginForm
