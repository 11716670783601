import { absoluteApiUrls, getRequestHeaders, handleError, getUrlFromTemplate } from 'utils/api'

export const login = async ({ email, password }) => {
  const result = {
    data: null,
    error: null
  }
  const method = 'POST'
  const url = absoluteApiUrls.user.login
  const headers = getRequestHeaders(false)
  const body = JSON.stringify({
    email,
    password
  })
  try {
    const response = await fetch(url, { method, headers, body })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }
  return result
}

export const getMe = async () => {
  const result = {
    data: null,
    error: null
  }

  const method = 'GET'
  const url = absoluteApiUrls.user.getMe
  const headers = getRequestHeaders(true)

  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }

  return result
}

export const getUserById = async (customerId) => {
  const result = {
    data: null,
    error: null
  }

  const method = 'GET'
  const url = getUrlFromTemplate(absoluteApiUrls.user.getUserById, { customerId })
  const headers = getRequestHeaders(true)
  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }
  return result
}

export const getUserByPhone = async (phone) => {
  const result = {
    data: null,
    error: null
  }
  const method = 'GET'
  const url = getUrlFromTemplate(absoluteApiUrls.user.getUserByPhone, { phone })
  const headers = getRequestHeaders(true)
  try {
    const response = await fetch(url, { method, headers })
    const responseData = await response.json()
    if (response.ok) {
      result.data = responseData
    } else {
      result.error = handleError(
        responseData.error || responseData,
        response.status
      )
    }
  } catch (e) {
    result.error = handleError(e)
  }
  return result
}
