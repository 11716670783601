export default {
  home: {
    path: '/'
  },
  shop: {
    path: '/shop'
  },
  pointOfSale: {
    path: '/point-of-sale'
  }
}
