import { createStore, combineReducers, applyMiddleware } from 'redux'
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { routerReducer, routerMiddleware } from 'react-router-redux'
import reduxCatch from 'redux-catch'
import thunk from 'redux-thunk'
import { browserHistory } from 'react-router'

import reduxDevToolsComposeEnhancer from './reduxDevTools'

import { appReducers, shopReducers } from 'reducers'

export const persist = (persistConfig, reducer) =>
  persistReducer({ ...persistConfig, storage }, reducer)

// use it to blacklist a nested key
const userErrorBlacklist = createTransform(
  null,
  (state) => {
    return { ...state, error: null }
  },
  { whitelist: ['user'] }
)

const appPersistConfig = {
  key: 'app',
  version: 1,
  transforms: [userErrorBlacklist]
}

// const shopPersistConfig = {
//   key: 'shop',
//   version: 1,
//   whitelist: ['customer'] // use it to whiteList a whole key of reducer
// }

const reducers = combineReducers({
  app: persist(appPersistConfig, appReducers),
  shop: shopReducers,
  routing: routerReducer
})

// ---- catch error ----//
function errorHandler (error, getState, lastAction) {
  console.error(error)
  console.debug('last action was', lastAction)
}

const store = createStore(reducers, reduxDevToolsComposeEnhancer(
  applyMiddleware(reduxCatch(errorHandler), thunk, routerMiddleware(browserHistory))
))

export const persistor = persistStore(store)

export default store
