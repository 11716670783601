import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import IconButton from '@material-ui/core/IconButton'

import SettingsPowerIcon from '@material-ui/icons/SettingsPower'

import ContentWrapper from 'components/layout/ContentWrapper'
import ShopSelectorForm from './ShopSelectorForm'
import { logout } from 'actions/auth'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  logout: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: 10
  },
  logo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: 22,
    width: '30px'
  },
  icon: {
    fontSize: 25
  }
})

const ShopHome = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const exitShop = () => {
    dispatch(logout(true))
  }

  return (
    <ContentWrapper
      paddingSpacing={0}
      className={classes.root}
    >
      <img src='/images/logo-dark.svg' alt='logo' className={classes.logo} />
      <IconButton
        className={classes.logout}
        onClick={exitShop}
      >
        <SettingsPowerIcon className={classes.icon} />
      </IconButton>
      <ShopSelectorForm />
    </ContentWrapper>
  )
}

export default ShopHome
