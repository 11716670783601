import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { getPublicationCategories } from 'utils/publications'
import { useSelector } from 'react-redux'
import { getPaymentMethodSelected, getProductQuantity } from 'reducers/shop'
import { isEmpty } from 'lodash'
import { DepositChoicesPopover } from '../DepositChoicesPopover'
const getColorFromCategory = (type) => {
  const categories = getPublicationCategories(type)
  switch (categories[0]) {
    case 'mains': return '#ACB1E1'
    case 'starters': return '#97C5AF'
    case 'desserts': return '#F1B2B2'
    case 'yaourts': return '#FDEBB4'
    case 'drinks': return '#B4CFDE'
    case 'snacks': return '#E9B381'
    case 'breakfasts': return '#F3D892'
    default: return '#CBCBCB' // others
  }
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 115,
    background: props => getColorFromCategory(props.type),
    '&:hover': {
      cursor: 'pointer'
    },
    '&:active': {
      transform: 'scale(.96)'
    },
    transition: 'transform .1s'
  },
  buttonRoot: {
    width: '100%',
    height: '100%',
    padding: [[8, 8, 8, 15]]
  },
  buttonLabel: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    textTransform: 'none',
    alignItems: 'flex-start',
    textAlign: 'left'
  },
  code: {
    fontSize: 20,
    letterSpacing: '.3px',
    lineHeight: '24px',
    margin: '0 0 5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  title: {
    fontSize: 14,
    letterSpacing: '.25px',
    lineHeight: '17px',
    margin: '0 0 5px',
    height: 34,
    overflow: 'hidden'
  },
  price: {
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: '1.25px',
    margin: 0
  },
  rippleVisible: {
    animationDuration: '250ms'
  }
})

const PublicationItem = ({ publication, updateProductQuantity }) => {
  const { idProductPublication: id, otherPublications, productTypeName: type, price, title, code = null } = publication
  const classes = useStyles({ type })
  const paymentMethodSelected = useSelector(getPaymentMethodSelected)
  const quantity = useSelector(state => getProductQuantity(state, { publicationId: id }))
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    if (!isEmpty(otherPublications) && isEmpty(paymentMethodSelected)) {
      setAnchorEl(event.currentTarget)
    } else {
      isEmpty(paymentMethodSelected) && updateProductQuantity(quantity + 1, id)
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Card
        className={classes.root}
        elevation={1}
      >
        <Button
          onClick={handleClick}
          classes={{
            root: classes.buttonRoot,
            label: classes.buttonLabel
          }}
          TouchRippleProps={{
            classes: { rippleVisible: classes.rippleVisible }
          }}
        >
          {code && <Typography className={classes.code} gutterBottom>{code}</Typography>}
          <Typography className={classes.title} gutterBottom>{title}</Typography>
          <Typography className={classes.price} gutterBottom>{`${price}€`}</Typography>
        </Button>
      </Card>
      <DepositChoicesPopover onClose={handleClose} isOpen={open} publication={publication} anchorEl={anchorEl} onSelected={updateProductQuantity} />
    </>
  )
}

export default PublicationItem
