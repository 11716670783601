import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    padding: props => theme.spacing(props.paddingSpacing)
  }
}))

const ContentWrapper = ({ paddingSpacing = 3, children, className }) => {
  const classes = useStyles({ paddingSpacing })
  return (
    <div className={clsx(classes.root, className)}>
      {children}
    </div>
  )
}

ContentWrapper.propTypes = {
  paddingSpacing: PropTypes.number
}

export default ContentWrapper
