import { initializeCart } from 'actions/shop/cart'
import { onEnter } from 'actions/utils'
import { getCitiesAndHubs } from './citiesAndHubs'
import { getSelectedHub } from 'reducers/shop'
import { getPathById } from 'utils/routes'

export const onEnterShop = store => {
  return onEnter({
    store,
    actionThunk: () => {
      return async dispatch => {
        try {
          await dispatch(getCitiesAndHubs())
        } catch (error) {
          console.error(error.message)
        }
      }
    }
  })
}

export const onEnterPointOfSale = (store) => {
  return onEnter({
    store,
    getReplacingPath: async getState => {
      // allow actionThunk to execute only if there is a selectedHub
      // TO DO: check permissions to access the route here
      const selectedHub = getSelectedHub(getState())
      if (!selectedHub) {
        return getPathById('shop')
      }
    },
    actionThunk: () => {
      return async (dispatch, getState) => {
        const selectedHub = getSelectedHub(getState())
        try {
          await dispatch(initializeCart({ idHub: selectedHub.idHub, withPaymentMethods: true }))
        } catch (error) {
          console.error(error)
        }
      }
    }
  })
}
