import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles, withStyles } from '@material-ui/core/styles'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined'
import SettingsPowerIcon from '@material-ui/icons/SettingsPower'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Badge from '@material-ui/core/Badge'
import Modal from '@material-ui/core/Modal'

import LeaveShopConfirmation from 'components/shop/LeaveShopConfirmation'
import { clearCartProducts } from 'actions/shop/cart'
import { goToRoute } from 'utils/routes'
import { getCartProducts } from 'reducers/shop'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
    marginLeft: '15px',
    marginRight: '15px'
  },
  action: {
    transform: 'translateY(15px)',
    marginLeft: '15px',
    cursor: 'pointer',
    color: 'grey'
  },
  iconBack: {
    transform: 'translate(0px, 6px)',
    marginRight: '10px',
    cursor: 'pointer',
    pointerEvents: ({ isProcessing }) => isProcessing && 'none'
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none'
  },
  section: {
    display: 'flex',
    justifyContent: 'space-beetween'
  },
  badge: {
    top: '14px',
    marginLeft: '15px'
  },
  cartIcon: {
    width: 20,
    height: 18,
    transform: 'translateY(4px)'
  }
})

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  }
}))(Badge)

const CartHeader = ({ title, showAction = false, showExit = false, handleCancel = null, isProcessing = false }) => {
  const classes = useStyles({ isProcessing })
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const products = useSelector(getCartProducts)
  const exitShop = () => {
    dispatch(goToRoute('shop'))
  }
  const handleClearCartProducts = () => {
    dispatch(clearCartProducts())
  }
  let quantity = 0
  products.forEach(element => {
    quantity = quantity + element.quantity
  })

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        <h3>{handleCancel && <ArrowBackIcon className={classes.iconBack} onClick={handleCancel} />}
          {title}
        </h3>
        {(showAction && showExit) || showAction
          ? (
            <StyledBadge className={classes.badge} badgeContent={quantity} color='primary'>
              <img src='/images/cart.svg' alt='cart' className={classes.cartIcon} />
            </StyledBadge>)
          : null}
      </div>
      {(showAction && showExit) || showAction
        ? <DeleteOutlineOutlinedIcon className={classes.action} onClick={handleClearCartProducts} />
        : showExit
          ? <SettingsPowerIcon className={classes.action} onClick={() => setIsModalOpen(true)} />
          : null}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby='Modale de confirmation'
        aria-describedby='Quitter mode shop'
        className={classes.modal}
      >
        <LeaveShopConfirmation onCancel={() => setIsModalOpen(false)} onConfirm={exitShop} />
      </Modal>
    </div>
  )
}

export default CartHeader
