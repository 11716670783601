import React, { useState, useEffect, useRef } from 'react'
import { useDebounce } from 'react-use'
import QrReader from 'react-qr-reader'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import MuiPhoneNumber from 'material-ui-phone-number'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/icons/Person'

import { ERROR_ASSIGN_CART_TO_CUSTOMER, ERROR_IDENTIFY_CUSTOMER_BY_ID, ERROR_IDENTIFY_CUSTORMER_BY_PHONE, GENERIC_ERROR } from 'utils/errors'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
  title: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  closeIcon: {
    cursor: 'pointer',
    color: 'grey'
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '200px'
  },
  content: {
    fontSize: '15px',
    marginRight: '20px',
    width: '350px'
  },
  input: {
    marginBottom: '5px'
  },
  reader: {
    width: '175px',
    height: '175px',
    backgroundColor: 'black'
  },
  avatar: {
    backgroundColor: '#C5DCFC'
  },
  personIcon: {
    color: 'primary',
    fontSize: 25
  },
  QRCodeInput: {
    position: 'absolute',
    width: 0,
    overflow: 'hidden',
    border: 0,
    padding: 0
  },
  error: {
    color: '#BF0000',
    letterSpacing: '0.25px'
  },
  noResult: {
    letterSpacing: '0.25px',
    color: 'grey'
  },
  changeIdentificationMethod: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  iconAlert: {
    '&.MuiAlert-standardWarning .MuiAlert-icon': {
      display: 'flex',
      alignItems: 'center'
    }
  },
  text: {
    paddingBottom: '20px'
  },
  columnSection: {
    flexDirection: 'column'
  },
  stateText: {
    fontSize: '15px',
    textAlign: 'center'
  }
})

export const CustomerIdentifierModal = ({
  onClose,
  isOpen,
  options,
  handleScan,
  handleScanError,
  onChangeInputValue,
  inputValue, onSubmit,
  onChangeValue,
  scanError,
  error,
  isAssignCartToCustomerError,
  cartError,
  setScanError,
  clearCustomerErrors,
  clearCustomersOptions
}) => {
  const [showCamera, setShowCamera] = useState(false)
  const [QRCodeValue, setQRCodeValue] = useState('')
  const dispatch = useDispatch()
  const [isScanFocused, setIsScanFocused] = useState(false)
  const qrCodeInputRef = useRef(null)

  useEffect(() => {
    if (isOpen && !showCamera && isScanFocused) {
      setTimeout(() => {
        setQRCodeValue('')
      }, 200)
    }
  }, [isOpen, showCamera, error, scanError, isScanFocused])

  const clearAll = () => {
    dispatch(clearCustomerErrors())
    dispatch(clearCustomersOptions())
    setScanError(null)
    onChangeInputValue('')
  }

  useEffect(() => {
    clearAll()
  }, [showCamera])

  useEffect(() => {
    if (!isOpen) {
      setShowCamera(false)
      setIsScanFocused(false)
    }
  }, [isOpen])

  // this useDebounce is used to call the handleScan function 500 ms after the first input has been sent to QRCodeValue
  useDebounce(() => {
    if (QRCodeValue === '') return
    handleScan(QRCodeValue)
  }, 500, [QRCodeValue])

  // this useDebounce is a bit different. It is used to avoid spamming the phone number. Also it is formating the URL that will be sent to the backend
  useDebounce(() => {
    if (inputValue === '') return
    const formated = encodeURIComponent(inputValue.replace(/\s/g, ''))
    onChangeValue(formated)
  }, 500, [inputValue])

  const classes = useStyles()

  const _onScannerClick = () => {
    if (showCamera) setShowCamera(false)
    if (qrCodeInputRef.current) qrCodeInputRef.current.focus()
    clearAll()
  }

  return (
    <Dialog fullWidth maxWidth='sm' open={isOpen} onClose={onClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title'>
        <input
          autoFocus
          onBlur={() => setIsScanFocused(false)}
          onFocus={() => setIsScanFocused(true)}
          autocomplete='off'
          ref={qrCodeInputRef}
          className={classes.QRCodeInput}
          value={QRCodeValue}
          onChange={e => setQRCodeValue(e.target.value)}
          type='text'
        />
        <div className={classes.title}>
          Identifier le Client
          <CloseIcon className={classes.closeIcon} onClick={onClose} />
        </div>
      </DialogTitle>
      <DialogContent>
        {showCamera
          ? (
            <DialogContentText>
              <div className={classes.section}>
                <div className={classes.content}>
                  <div className={classes.text}>Placez le QR code du client devant l'appareil photo.</div>
                  Utiliser <span className={classes.changeIdentificationMethod} onClick={_onScannerClick}>le scanner.</span>
                </div>

                <QrReader
                  className={classes.reader}
                  delay={1000}
                  onError={handleScanError}
                  onScan={handleScan}
                />
              </div>
            </DialogContentText>
            )
          : (
            <DialogContentText>
              <div className={classes.section}>
                <div className={classes.content}>
                  <div className={classes.text}>Scannez le QR code du client (disponible sur son app FoodChéri) ou saisissez le numéro de téléphone du client.</div>
                  Pas de scanner ? Utiliser <span className={classes.changeIdentificationMethod} onClick={() => setShowCamera(true)}>l'appareil photo.</span>
                </div>
                <div className={classes.columnSection}>
                  <img
                    src='/images/qrcode.png'
                    alt='QR Code Image'
                    className={classes.reader}
                  />
                  {isScanFocused ? <div className={classes.stateText}>En attente de scan ...</div> : <div className={classes.stateText}>Utiliser <span className={classes.changeIdentificationMethod} onClick={_onScannerClick}>le scanner.</span> </div>}
                </div>
              </div>
            </DialogContentText>
            )}
        <MuiPhoneNumber onFocus={() => setScanError(false)} id='phoneInput' value={inputValue} className={classes.input} label='Numéro de téléphone' defaultCountry='fr' fullWidth onChange={(value) => onChangeInputValue(value)} />
        <div>
          <List dense={false}>
            {options.map(option => (
              <ListItem key={option.id}>
                <ListItemAvatar>
                  <Avatar className={classes.avatar}>
                    <PersonIcon className={classes.personIcon} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${option.firstName} ${option.lastName}`}
                  secondary={option.email}
                />
                <ListItemSecondaryAction>
                  <Button onClick={() => onSubmit(option)} variant='contained' size='medium' color='primary'>
                    Identifier
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            {[ERROR_IDENTIFY_CUSTOMER_BY_ID, ERROR_ASSIGN_CART_TO_CUSTOMER, GENERIC_ERROR].includes(error) || (scanError && error !== ERROR_IDENTIFY_CUSTORMER_BY_PHONE)
              ? <p className={classes.error}>{error || scanError}</p>
              : null}
            {error === ERROR_IDENTIFY_CUSTORMER_BY_PHONE && <p className={classes.noResult}>{error}</p>}
          </List>
        </div>
      </DialogContent>
    </Dialog>
  )
}
