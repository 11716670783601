import { simpleAction } from 'actions/utils'

import * as api from 'api/citiesAndHubs'

import { showError } from 'actions/errors'

import actionTypes from 'actions/types'
import { logout } from 'actions/auth'

const {
  SET_CITIES_AND_HUBS_ACTION, SET_CITIES_AND_HUBS_LOADING_START_ACTION,
  SET_CITIES_AND_HUBS_LOADING_END_ACTION, SET_CITIES_AND_HUBS_LOADING_ERROR_ACTION,
  SET_SELECTED_HUB_ACTION
} = actionTypes

export const getCitiesAndHubs = () => {
  return simpleAction({
    start: SET_CITIES_AND_HUBS_LOADING_START_ACTION,
    end: SET_CITIES_AND_HUBS_LOADING_END_ACTION,
    errorType: SET_CITIES_AND_HUBS_LOADING_ERROR_ACTION
  }, async dispatch => {
    try {
      const { data, error } = await api.getCitiesAndHubs()
      if (data) {
        dispatch({
          type: SET_CITIES_AND_HUBS_ACTION,
          citiesAndHubs: data
        })
      } else if (error.code === 401) {
        dispatch(logout(true))
      } else {
        throw new Error('La récupération des villes et des hubs a échoué')
      }
    } catch (error) {
      dispatch(showError({ error: error.message, type: SET_CITIES_AND_HUBS_LOADING_ERROR_ACTION }))
    }
  })
}

export const setSelectedHub = hub => {
  return async dispatch => {
    dispatch({
      type: SET_SELECTED_HUB_ACTION,
      selectedHub: hub
    })
  }
}
