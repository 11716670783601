import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import FaceRoundedIcon from '@material-ui/icons/FaceRounded'
import CancelIcon from '@material-ui/icons/Cancel'

import { CustomerIdentifierModal } from './CustomerIdentifierModal'
import { disIdentifyCustomer, identifyCustomer, getCustomersByPhone, clearCustomersOptions, clearCustomerErrors } from 'actions/shop/customers'
import { getCustomer, getCustomerError, getCustomersOptions } from 'reducers/shop'
import { ERROR_IDENTIFY_CUSTOMER_BY_ID } from 'utils/errors'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  identificationStatus: {
    color: ({ identifiedCustomer, error }) => error && !identifiedCustomer ? '#BF0000' : '#9B9B9B',
    letterSpacing: '0.25px',
    fontSize: '14px',
    lineHeight: '17px',
    fontWeight: 400,
    padding: 5
  },
  identifierContainer: {
    minHeight: 32,
    maxWidth: '100%',
    display: 'inline-flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: ({ identifiedCustomer }) => identifiedCustomer ? '#2196F3' : '#E10050',
    borderRadius: 70,
    cursor: ({ identifiedCustomer }) => identifiedCustomer ? 'default' : 'pointer',
    pointerEvents: ({ canRemoveCustomer }) => !canRemoveCustomer && 'none'
  },
  faceIconContainer: {
    display: 'flex',
    backgroundColor: ({ identifiedCustomer }) => identifiedCustomer ? '#1976D2' : '#9D0038',
    borderRadius: '50%',
    width: 32,
    height: 32,
    flexShrink: 0
  },
  faceIcon: {
    margin: 'auto',
    color: '#ffffff',
    fontSize: '20px'
  },
  name: {
    lineHeight: '20px',
    color: '#ffffff',
    fontSize: ({ identifiedCustomer }) => identifiedCustomer ? '13px' : '14px',
    fontWeight: ({ identifiedCustomer }) => identifiedCustomer ? 400 : 500,
    flexGrow: 1,
    textAlign: 'center',
    minWidth: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '0 7px',
    paddingRight: ({ canRemoveCustomer }) => !canRemoveCustomer && '15px'
  },
  crossContainer: {
    display: 'flex',
    marginRight: 8,
    flexShrink: 0,
    padding: 0,
    backgroundColor: 'transparent',
    border: 'none'
  },
  cross: {
    color: '#ffffff',
    cursor: 'pointer'
  },
  arrowContainer: {
    display: 'flex',
    marginRight: 8,
    flexShrink: 0
  },
  arrow: {
    color: '#ffffff'
  }
})

const CustomerIdentifier = ({ showIdentificationStatus = false, canRemoveCustomer = true, hasProducts = false }) => {
  const identifiedCustomer = useSelector(getCustomer)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const options = useSelector(getCustomersOptions)
  const [inputValue, setInputValue] = useState('')
  const dispatch = useDispatch()
  const error = useSelector(getCustomerError)
  const [scanError, setScanError] = useState(null)
  const classes = useStyles({ identifiedCustomer, error, canRemoveCustomer })

  const handleOpenModal = () => setIsOpenModal(true)

  const handleCloseModal = () => {
    setIsOpenModal(false)
    dispatch(clearCustomersOptions())
    setInputValue('')
    dispatch(clearCustomerErrors())
  }

  const removeCustomer = () => {
    if (identifiedCustomer) {
      dispatch(disIdentifyCustomer())
    }
  }

  useEffect(() => {
    if (!error && identifiedCustomer) handleCloseModal()
  }, [error, identifiedCustomer])

  const handleScan = data => {
    setScanError(null)
    if (data) {
      setInputValue('')
      document.getElementById('phoneInput').blur()
      try {
        const url = new URL(data)
        const customerId = url.searchParams.get('user')
        dispatch(identifyCustomer({ id: customerId }))
      } catch (e) {
        setScanError(ERROR_IDENTIFY_CUSTOMER_BY_ID)
      }
    }
  }

  useEffect(() => {
    if (!isOpenModal) {
      setScanError(null)
    }
  }, [isOpenModal])

  const handleScanError = err => console.error(err)

  const handleChangeValue = async (value) => {
    dispatch(getCustomersByPhone(value))
  }

  const onSubmit = (value) => {
    dispatch(identifyCustomer({ customer: value }))
  }

  const handleChangeInputValue = (value) => {
    setInputValue(value)
  }

  return (
    <div className={classes.root}>
      {showIdentificationStatus
        ? (
          <div className={classes.identificationStatus}>{!identifiedCustomer && !error ? 'Avantages ou remises ?' : identifiedCustomer ? 'Client identifié' : 'Client non identifié'}</div>)
        : null}
      <div className={classes.identifierContainer} onClick={identifiedCustomer ? null : handleOpenModal}>
        <div className={classes.faceIconContainer}>
          <FaceRoundedIcon className={classes.faceIcon} />
        </div>
        <div className={classes.name}>{identifiedCustomer ? `${identifiedCustomer.firstName} ${identifiedCustomer.lastName}` : 'Identifiez le Client'}</div>
        {identifiedCustomer
          ? canRemoveCustomer
              ? (
                <button className={classes.crossContainer} onClick={removeCustomer}>
                  <CancelIcon className={classes.cross} />
                </button>
                )
              : null
          : (
            <div className={classes.arrowContainer}>
              <NavigateNextIcon className={classes.arrow} />
            </div>
            )}
      </div>
      <CustomerIdentifierModal
        isOpen={isOpenModal}
        onClose={handleCloseModal}
        handleScan={handleScan}
        handleScanError={handleScanError}
        options={options}
        inputValue={inputValue}
        clearCustomerErrors={clearCustomerErrors}
        clearCustomersOptions={clearCustomersOptions}
        onChangeInputValue={handleChangeInputValue}
        onChangeValue={handleChangeValue}
        onSubmit={onSubmit}
        error={error}
        scanError={scanError}
        setScanError={setScanError}
      />
    </div>
  )
}
export default CustomerIdentifier
