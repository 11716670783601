import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import PublicationsFilter from 'components/shop/publications/PublicationsFilter'
import PublicationsList from 'components/shop/publications/PublicationsList'
import PublicationSearchWidget from 'components/shop/publications/PublicationSearchWidget'
import { getShopPublications } from 'reducers/shop'
import { getPublicationCategories } from 'utils/publications'

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#E8E5E5',
    position: 'relative',
    overflowX: 'hidden',
    '&>:nth-child(2)': {
      flexGrow: 1
    },
    '&>:nth-child(3)': {
      position: 'absolute',
      bottom: 15,
      left: 15
    }
  }
})

export const FILTERS = [
  {
    label: 'Tout',
    filter: 'all'
  },
  {
    label: 'Petits déjeuners',
    filter: 'breakfasts'
  },
  {
    label: 'Plats',
    filter: 'mains'
  },
  {
    label: 'Desserts',
    filter: 'desserts'
  },
  {
    label: 'Yaourts',
    filter: 'yaourts'
  },
  {
    label: 'Entrées',
    filter: 'starters'
  },
  {
    label: 'Boissons',
    filter: 'drinks'
  },
  {
    label: 'Snacks',
    filter: 'snacks'
  },
  {
    label: 'Autres',
    filter: 'others'
  }
]

const Publications = () => {
  const classes = useStyles()
  const [typeFilter, setTypeFilter] = useState(0) // the first element is the defautl filter, here it is 'ALL'
  const [textFilter, setTextFilter] = useState('')
  const shopPublications = useSelector(getShopPublications)
  const availableFilters = FILTERS.filter(({ filter }) => filter === 'all' || shopPublications.some(({ productTypeName }) => getPublicationCategories(productTypeName).includes(filter)))

  const handleTypeFilterChange = filter => {
    setTypeFilter(filter)
  }

  const handleTextFilterChange = filter => {
    if (typeof filter === 'string' && (filter.length > 1 || filter === '')) {
      setTextFilter(filter)
    }
  }

  return (
    <div
      className={classes.root}
    >
      <PublicationsFilter
        onChangeFilter={handleTypeFilterChange}
        activeFilter={typeFilter}
        filters={availableFilters}
      />
      <PublicationsList
        categoryFilter={availableFilters[typeFilter]?.filter || 'all'}
        textFilter={textFilter}
        publications={shopPublications}
      />
      <PublicationSearchWidget
        onChangeFilter={handleTextFilterChange}
      />
    </div>
  )
}

export default Publications
