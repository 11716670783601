import { composeWithDevTools } from 'redux-devtools-extension'

// "replacer" prevents the extension from hanging (because of JSON formatting)
// . the external "seen" ensures that the cache is global to the entire state
// . the custom encode function is based onParse's encode with a better usage of seen
const encode = (value, seen) => {
  if (toString.call(value) === '[object Date]') {
    if (isNaN(value)) {
      throw new Error('Tried to encode an invalid date.')
    }
    return { __type: 'Date', iso: value.toJSON() }
  }

  if (toString.call(value) === '[object RegExp]' && typeof value.source === 'string') {
    return value.source
  }

  if (Array.isArray(value)) {
    return value.map((v) => {
      return encode(v, seen)
    })
  }
  return value
}

const seen = new Set()
const composeEnhancers = composeWithDevTools({
  maxAge: 20,
  stateSanitizer: state => {
    seen.clear()
    return state
  },
  serialize: {
    replacer: (key, value) => {
      if (value && value.constructor) {
        return encode(value, seen)
      }
      return value
    }
  }
})

export default composeEnhancers
