const PUBLICATION_TYPES = {
  MAIN_COURSE: ['mains'],
  MAIN_COURSE_SALAD: ['mains'],
  BREAD: ['others'],
  DESSERT: ['desserts'],
  DESSERT_SALAD: ['desserts'],
  YAOURT: ['yaourts'],
  STARTER: ['starters'],
  STARTER_SALAD: ['starters'],
  DRINK: ['drinks'],
  DRINK_ALCOHOL: ['drinks'],
  SNACK: ['snacks'],
  SPECIAL_EVENT: ['others'],
  BREAKFAST: ['breakfasts']
}

/**
 * returns the categories the publication belongs to
 * @param {String} type
 * @returns {Array}
 */
export const getPublicationCategories = (type) => {
  return PUBLICATION_TYPES[type] || ['others']
}
