export default {
  // ------ APP ACTIONS ------  //
  SET_DEVICE_TYPE_ACTION: 'set-device-type',
  PAGE_LOADING_START_ACTION: 'page-loading-start',
  PAGE_LOADING_END_ACTION: 'page-loading-end',

  // ------ USER ACTIONS ------ //
  LOGIN_LOADING_START_ACTION: 'login-loading-start',
  LOGIN_LOADING_END_ACTION: 'login-loading-end',
  LOGOUT_ACTION: 'logout',
  LOGIN_ERROR_ACTION: 'login-error',
  CLEAR_LOGIN_ERROR_ACTION: 'clear-login-error',
  GET_ME_LOADING_START_ACTION: 'get-me-loading-start',
  GET_ME_LOADING_END_ACTION: 'get-me-loading-end',
  UPDATE_USER_ACTION: 'update-user',

  // ------ SHOP ACTIONS ------ //
  SET_SHOP_INFO_MESSAGE_ACTION: 'set-shop-info-message',
  CLEAR_SHOP_INFO_MESSAGE_ACTION: 'clear-shop-info-message',

  // CITIES_AND_HUBS
  SET_CITIES_AND_HUBS_ACTION: 'set-cities-and-hubs',
  SET_SELECTED_HUB_ACTION: 'set-selected-hub',
  SET_CITIES_AND_HUBS_LOADING_START_ACTION: 'set-cities-and-hubs-loading-start',
  SET_CITIES_AND_HUBS_LOADING_END_ACTION: 'set-cities-and-hubs-loading-end',
  SET_CITIES_AND_HUBS_LOADING_ERROR_ACTION: 'set-cities-and-hubs-loading-error',

  // CUSTOMER
  IDENTIFY_CUSTOMER_ACTION: 'identify-customer',
  DISIDENTIFY_CUSTOMER_ACTION: 'dis-identify-customer-success',
  DISIDENTIFY_CUSTOMER_LOADING_START_ACTION: 'dis-identify-customer-loading-start',
  DISIDENTIFY_CUSTOMER_LOADING_END_ACTION: 'dis-identify-customer-loading-end',
  DISIDENTIFY_CUSTOMER_ERROR_ACTION: 'dis-identify-customer-error',
  IDENTIFY_CUSTOMER_ERROR_ACTION: 'identify-customer-error',
  IDENTIFY_CUSTOMER_LOADING_START_ACTION: 'identify-customer-loading-start',
  IDENTIFY_CUSTOMER_LOADING_END_ACTION: 'identify-customer-loading-end',
  GET_CUSTOMERS_OPTIONS_ERROR_ACTION: 'get-customers-options-error',
  GET_CUSTOMERS_OPTIONS_LOADING_START_ACTION: 'get-customers-options-loading-start',
  GET_CUSTOMERS_OPTIONS_LOADING_END_ACTION: 'get-customers-options-loading-end',
  GET_CUSTOMERS_OPTIONS_ACTION: 'get-customers-options',
  CLEAR_CUSTOMERS_OPTIONS_ACTION: 'clear-customers-options',
  CLEAR_CUSTOMER_ERROR: 'clear-customer-error',

  // SECTIONS
  GET_SECTIONS_LOADING_START_ACTION: 'get-sections-loading-start',
  GET_SECTIONS_LOADING_END_ACTION: 'get-sections-loading-end',
  GET_SECTIONS_SUCCESS_ACTION: 'get-sections-success',
  GET_SECTIONS_ERROR_ACTION: 'get-sections-error',
  CLEAR_SECTIONS_ERROR: 'clear-sections-error',
  SET_HUB_PICKER_ERROR_ACTION: 'set-hub-picker-error',

  // CART
  INITIALIZE_CART_LOADING_START_ACTION: 'initialize-cart-loading-start',
  INITIALIZE_CART_LOADING_END_ACTION: 'initialize-cart-loading-end',
  INITIALIZE_CART_ERROR_ACTION: 'initialize-cart-error',
  INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION: 'initialize-cart-non-blocking-errors',
  INITIALIZE_CART_SUCCESS_ACTION: 'initialize-cart-success',
  UPDATE_CART_PRODUCTS_LOADING_START_ACTION: 'update-cart-products-loading-start',
  UPDATE_CART_PRODUCTS_LOADING_END_ACTION: 'update-cart-products-loading-end',
  UPDATE_CART_PRODUCTS_ERROR_ACTION: 'update-cart-products-error',
  UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION: 'update-cart-products-non-blocking-errors',
  UPDATE_CART_PRODUCTS_SUCCESS_ACTION: 'update-cart-products-success',
  CLEAR_CART_PRODUCTS_LOADING_START_ACTION: 'clear-cart-products-loading-start',
  CLEAR_CART_PRODUCTS_LOADING_END_ACTION: 'clear-cart-products-loading-end',
  CLEAR_CART_PRODUCTS_ERROR_ACTION: 'clear-cart-products-error',
  RELOAD_CART_LOADING_START_ACTION: 'reload-cart-loading-start',
  RELOAD_CART_LOADING_END_ACTION: 'reload-cart-loading-end',
  RELOAD_CART_ERROR_ACTION: 'reload-cart-error',
  RELOAD_CART_NON_BLOCKING_ERRORS_ACTION: 'reload-cart-non-blocking-errors',
  RELOAD_CART_SUCCESS_ACTION: 'reload-cart-success',
  UPDATE_CART_DISCOUNTS_LOADING_START_ACTION: 'update-cart-discounts-loading-start',
  UPDATE_CART_DISCOUNTS_LOADING_END_ACTION: 'update-cart-discounts-loading-end',
  UPDATE_CART_DISCOUNTS_ERROR_ACTION: 'update-cart-discounts-error',
  UPDATE_CART_DISCOUNTS_NON_BLOCKING_ERRORS_ACTION: 'update-cart-discounts-non-blocking-errors',
  UPDATE_CART_DISCOUNTS_SUCCESS_ACTION: 'update-cart-discounts-success',
  CLEAR_CART_DISCOUNTS_ERROR: 'clear-cart-discounts-error',
  RESET_CART_ORDER_ACTION_ERROR: 'reset-cart-order-error',
  RESET_CART_ORDER_NON_BLOCKING_ERRORS_ACTION: 'reset-cart-order-non-blocking-errors',

  // CHECKOUT
  SELECT_PAYMENT_METHOD: 'select-payment-method',
  SET_CHECKOUT_LOADING_START_ACTION: 'set-checkout-loading-start',
  SET_CHECKOUT_LOADING_END_ACTION: 'set-checkout-loading-end',
  SET_CHECKOUT_ERROR_ACTION: 'set-checkout-error',
  CLEAR_CART_ACTION: 'clear-cart',
  CLEAR_CHECKOUT_ERROR: 'clear-checkout-error',
  PAYMENT_PROCESSING_ACTION: 'payment-processing',
  CONFIRM_PAYMENT_ACTION_ERROR: 'confirm-payment-error',
  CONFIRM_PAYMENT_ACTION_SUCCESS: 'confirm-payment-success',
  CANCEL_PAYMENT_ACTION: 'cancel-payment'
}
