import React from 'react'
import ReactDOM from 'react-dom'
import { syncHistoryWithStore } from 'react-router-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { browserHistory } from 'react-router'
import { Provider } from 'react-redux'

import { ThemeProvider } from 'react-jss'
import { DEFAULT } from './themes/default'

import Routes from './Routes'
import store, { persistor } from './store'

require('dotenv').config()

const history = syncHistoryWithStore(browserHistory, store)

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={DEFAULT}>
          <Routes history={history} store={store} />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
