import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import CartProduct from 'components/shop/CartProduct'
import { getCart } from 'reducers/shop'
import { updateCartProducts } from 'actions/shop/cart'
import { usePrevious } from 'hooks/usePrevious'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    overflow: 'auto',
    width: '100%',
    padding: '0 2px',
    '&>:not(:last-child)': {
      marginBottom: 2
    }
  },
  cartProducts: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start'
  },
  anchor: {
    height: 0
  },
  noProduct: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

const ProductsMenu = ({ products, isCartPayment = false }) => {
  const classes = useStyles()
  const { cart } = useSelector(getCart)
  const dispatch = useDispatch()
  const anchorRef = useRef(null)
  const previousCartProductsLength = usePrevious(products.length)

  const updateProductQuantity = async (quantity, idProductPublication) => {
    if (!isCartPayment) {
      await dispatch(updateCartProducts({ cartUuid: cart?.uuid, idProductPublication, quantity }))
    }
  }

  useEffect(() => {
    if (previousCartProductsLength < products.length) {
      anchorRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [products.length])

  return (
    <div className={classes.root}>
      {Array.isArray(products) && products.length
        ? (
          <>
            <div className={classes.cartProducts}>
              {products.reverse().map(({ idPublication, code, title, quantity, isReusable, originalPrice }) => (
                <CartProduct
                  idPublication={idPublication}
                  code={code}
                  title={title}
                  originalPrice={originalPrice}
                  quantity={quantity}
                  isReusable={isReusable}
                  updateQuantity={updateProductQuantity}
                  key={idPublication}
                />
              ))}
            </div>
            <div className={classes.anchor} ref={anchorRef} />
          </>
          )
        : <div className={classes.noProduct}> Le panier est vide. </div>}
    </div>
  )
}

export default ProductsMenu
