import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
  root: {
    width: 460,
    minHeight: 283,
    padding: '31px 50px',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between'
  },
  textContainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    flex: 1
  },
  text: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '19.19px',
    textAlign: 'center',
    margin: 15
  },
  buttonsContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between'
  },
  button: {
    width: 130
  }
})

const LeaveShopConfirmation = ({ onCancel = () => {}, onConfirm = () => {} }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.textContainer}>
        <div className={classes.text}>Vous allez quitter le mode Shop.</div>
        <div className={classes.text}>Souhaitez-vous continuer ?</div>
      </div>
      <div className={classes.buttonsContainer}>
        <Button className={classes.button} variant='outlined' color='primary' onClick={onCancel}>Annuler</Button>
        <Button className={classes.button} variant='contained' color='primary' onClick={onConfirm}>Quitter</Button>
      </div>
    </div>
  )
}

export default LeaveShopConfirmation
