import React from 'react'
import { isEmpty } from 'lodash-es'
import { useSelector } from 'react-redux'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'

import { getEuroLabel } from 'utils/text'
import { getCartProducts } from 'reducers/shop'

const useStyles = makeStyles({
  title: {
    padding: '15px 15px 10px 15px',
    fontSize: '14px'
  },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px 15px 25px 15px'

  },
  container: {
    cursor: 'pointer',
    borderRadius: '4px',
    height: '100px',
    width: '145px'
  },
  isReusable: {
    backgroundColor: 'rgba(65, 134, 95, 0.2)',
    '&:hover': {
      backgroundColor: 'rgba(65, 134, 95, 0.25)'
    },
    '&:active': {
      backgroundColor: 'rgba(65, 134, 95, 0.4)',
      border: '2px solid #66B789'
    }
  },
  isReusableeNotFirst: {
    marginLeft: '15px'
  },
  isDisposable: {
    backgroundColor: 'rgba(230, 230, 230, 0.8 )',
    '&:hover': {
      backgroundColor: 'rgba(230, 230, 230, 1 )'
    },
    '&:active': {
      backgroundColor: 'rgba(186,186,186, 0.7)',
      border: '2px solid #C4C4C4'
    }
  },
  isDisposableNotFirst: {
    marginLeft: '15px'
  },
  subTitle: {
    paddingLeft: '10px',
    paddingTop: '10px'
  },
  subSection: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px 10px 0 10px'
  },
  disposableIcon: {
    width: 22,
    height: 20
  },
  depositIcon: {
    width: 14,
    height: 21
  },
  textDLC: {
    paddingLeft: '10px',
    fontSize: '12px'
  },
  textDLCDisabled: {
    color: 'transparent'
  }
})

export const DepositChoicesPopover = ({ onClose, isOpen, publication, anchorEl, onSelected }) => {
  const classes = useStyles()
  const { code = 'G54', otherPublications, isReusable } = publication
  const products = useSelector(getCartProducts)

  const handleClick = async (id) => {
    const productIsInCart = products.filter(value => value.idPublication === id)
    const quantity = isEmpty(productIsInCart) ? 0 : productIsInCart[0].quantity
    onSelected(quantity + 1, id)
    onClose()
  }

  const disposableProducts = []
  const reusableProducts = []
  isReusable === false ? disposableProducts.push(publication) : reusableProducts.push(publication)
  !isEmpty(otherPublications) && Array.isArray(otherPublications) && otherPublications.forEach(element => {
    element.isReusable === false ? disposableProducts.push(element) : reusableProducts.push(element)
  })

  const idPopover = isOpen ? 'simple-popover' : undefined
  return (
    <Popover
      id={idPopover}
      open={isOpen}
      anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <Typography className={classes.title}> {isEmpty(disposableProducts) || isEmpty(reusableProducts) ? 'Indiquer la DLC pour' : 'Indiquer le contenant pour'}
        <strong> {code}</strong> :
      </Typography>
      <div className={classes.section}>
        {reusableProducts.map((product, index) => (
          <div
            key={product.idProductPublication}
            className={clsx(classes.container, classes.isReusable, index > 0 && classes.isReusableeNotFirst)} onClick={() => handleClick(product.idProductPublication)}
          >
            <div className={classes.subTitle}>Consigne</div>
            <div className={clsx(classes.textDLC, !product.endSaleDt && classes.textDLCDisabled)}>
              DLC : {new Date(product.endSaleDt).toLocaleString().split(',')[0]}
            </div>
            <div className={classes.subSection}>
              <img src='/images/deposit-black.svg' alt='deposit' className={classes.disposableIcon} />
              <div>+ {getEuroLabel({ count: 2, decimals: 0 })}</div>
            </div>
          </div>
        )
        )}
        {disposableProducts.map((product, index) =>
          (
            <div
              key={product.idProductPublication}
              className={clsx(classes.container, classes.isDisposable, (index > 0 || (index === 0 && disposableProducts.length > 0 && !isEmpty(reusableProducts))) && classes.isDisposableNotFirst)}
              onClick={() => handleClick(disposableProducts[0].idProductPublication)}
            >
              <div className={classes.subTitle}>Jetable</div>
              <div className={clsx(classes.textDLC, !product.endSaleDt && classes.textDLCDisabled)}>
                DLC : {new Date(product.endSaleDt).toLocaleString().split(',')[0]}
              </div>
              <div className={classes.subSection}>
                <img src='/images/disposable.svg' alt='disposable' className={classes.disposableIcon} />
                <div>{getEuroLabel({ count: 0, decimals: 0 })}</div>
              </div>
            </div>

          ))}
      </div>
    </Popover>
  )
}
