import * as cookie from 'utils/cookies'
import * as api from 'api/users'
import { ERROR_LOGIN } from 'utils/errors'
import {
  simpleAction
} from './utils'
import { clearLoginError, updateUser } from './users'
import { getErrorMessageFr, showError } from './errors'
import actionTypes from './types'
import { goToRoute } from 'utils/routes'

const { LOGIN_ERROR_ACTION, LOGOUT_ACTION, LOGIN_LOADING_START_ACTION, LOGIN_LOADING_END_ACTION } = actionTypes

const SECURITY_COOKIE_KEY = cookie.KEY.security

export const login = ({ email, password }) => {
  return simpleAction({ start: LOGIN_LOADING_START_ACTION, end: LOGIN_LOADING_END_ACTION, errorType: LOGIN_ERROR_ACTION }, async (dispatch) => {
    try {
      const result = await api.login({ email, password })
      if (result && result.data && result.data.token) {
        cookie.set(SECURITY_COOKIE_KEY, result.data.token)
        await dispatch(getMeOnLogin())
        dispatch(clearLoginError())
        dispatch(goToRoute('shop'))
      } else {
        throw new Error('Failed to login')
      }
    } catch {
      dispatch(showError({ error: getErrorMessageFr(ERROR_LOGIN), type: LOGIN_ERROR_ACTION }))
    }
  })
}

export const logout = (redirect = false) => (dispatch) => {
  cookie.remove(SECURITY_COOKIE_KEY)
  dispatch({ type: LOGOUT_ACTION })
  if (redirect) {
    dispatch(goToRoute('home'))
  }
}

// use to avoid two actions with loader
const getMeOnLogin = () => {
  return async (dispatch) => {
    try {
      const result = await api.getMe()
      if (result.error) {
        throw new Error('Failed to get user information')
      } else {
        dispatch(updateUser(result.data))
      }
    } catch {
      dispatch(showError({ error: getErrorMessageFr(ERROR_LOGIN), type: LOGIN_ERROR_ACTION }))
    }
  }
}
