import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'

import ContentWrapper from 'components/layout/ContentWrapper'
import PublicationItem from './PublicationItem'
import { getPublicationCategories } from 'utils/publications'
import { updateCartProducts } from 'actions/shop/cart'
import { getCart } from 'reducers/shop'
import { FILTERS } from 'containers/Shop/PointOfSale/Publications'

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
    padding: [[30, 15]]
  },
  publicationsContainer: {
    width: '100%',
    paddingBottom: 80,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 154px)',
    gridGap: 10
  }
})

const PublicationsList = ({ categoryFilter = 'all', textFilter = '', publications = [] }) => {
  const dispatch = useDispatch()
  const { cart } = useSelector(getCart)
  const classes = useStyles()

  const [internalFilter, setInternalFilter] = useState(categoryFilter)
  useEffect(() => {
    setInternalFilter(categoryFilter)
  }, [categoryFilter])

  return (
    <ContentWrapper
      className={classes.root}
    >
      <div className={classes.publicationsContainer}>
        {
          Array.isArray(publications) &&
          publications
            .filter(publication => {
              return (internalFilter !== 'all' ? getPublicationCategories(publication?.productTypeName).includes(internalFilter) : true) &&
                (textFilter ? publication?.code?.toLowerCase().includes(textFilter.toLowerCase()) || publication?.title?.toLowerCase().includes(textFilter.toLowerCase()) : true)
            })
            .sort(function (publicationA, publicationB) {
              const codeA = publicationA.code ? publicationA.code : ''
              const codeB = publicationB.code ? publicationB.code : ''
              return codeA.localeCompare(codeB)
            })
            .sort((publicationA, publicationB) => {
              const publicationACategory = getPublicationCategories(publicationA?.productTypeName)[0]
              const publicationAIndex = FILTERS.findIndex(({ filter }) => filter === publicationACategory)
              const publicationBCategory = getPublicationCategories(publicationB?.productTypeName)[0]
              const publicationBIndex = FILTERS.findIndex(({ filter }) => filter === publicationBCategory)
              return publicationAIndex - publicationBIndex
            })
            .map(publication => (
              <PublicationItem
                publication={publication}
                key={publication.idProductPublication}
                updateProductQuantity={async (quantity, id) => await dispatch(updateCartProducts({ cartUuid: cart?.uuid, idProductPublication: id, quantity }))}
              />
            ))
        }
      </div>
    </ContentWrapper>
  )
}

export default PublicationsList
