
import actionTypes from 'actions/types'

const initialState = {
  user: { loading: false, error: null, user: null },
  device: 'desktop',
  loading: false
}

const appReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PAGE_LOADING_START_ACTION:
      return {
        ...state,
        loading: true
      }
    case actionTypes.PAGE_LOADING_END_ACTION:
      return {
        ...state,
        loading: false
      }
    case actionTypes.LOGIN_LOADING_START_ACTION:
      return {
        ...state,
        user: { ...state.user, loading: true }
      }
    case actionTypes.LOGIN_LOADING_END_ACTION:
      return {
        ...state,
        user: { ...state.user, loading: false }
      }
    case actionTypes.GET_ME_LOADING_START_ACTION:
      return {
        ...state,
        user: { ...state.user, loading: true }
      }
    case actionTypes.GET_ME_LOADING_END_ACTION:
      return {
        ...state,
        user: { ...state.user, loading: false }
      }
    case actionTypes.SET_DEVICE_TYPE_ACTION:
      return {
        ...state,
        device: action.device
      }
    case actionTypes.UPDATE_USER_ACTION:
      return {
        ...state,
        user: { ...state.user, user: action.user }
      }
    case actionTypes.LOGOUT_ACTION:
      return {
        ...state,
        user: { error: null, loading: false, user: null }
      }
    case actionTypes.LOGIN_ERROR_ACTION:
      return {
        ...state,
        user: { ...state.user, error: action.error }
      }
    case actionTypes.CLEAR_LOGIN_ERROR_ACTION:
      return {
        ...state,
        user: { ...state.user, error: null }
      }
    default:
      return state
  }
}

export const getDevice = (state) => state.app.device

export const getUser = (state) => state.app.user.user
export const getUserError = (state) => state.app.user.error
export const getUserLoading = (state) => state.app.user.loading

export default appReducers
