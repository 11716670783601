import { push, goBack as goBackRouter } from 'react-router-redux'
import ROUTES from 'config/routes'

export const fullScreenRoutes = [ROUTES.pointOfSale]

export const getRouteById = (id) => (
  ROUTES[id] || ROUTES.home
)

/**
 * getPathFromTemplate function
 * @param  {string} template the route path template
 * @param  {object} params   an key value list of the route parameters
 * @return {string}          the resulting route path
 */
export const getPathFromTemplate = (template, params) => {
  if (!template) {
    return '/'
  }

  if (!params || Object.keys(params).length === 0) {
    return template
  }

  let newPath = template

  Object
    .entries(params)
    .forEach(
      ([key, value]) => {
        newPath = newPath.replace(`:${key}`, value)
      }
    )

  return newPath
}

/**
 * Push routes in router's history
 * @param {String} id
 * @param {Object} params
 */
export const goToRoute = (id, params = {}) => {
  let path = '/404'
  if (pathIdExists(id)) {
    path = getPathById(id, params)
  }
  return push(path)
}
/**
 * Return to previous route
 */
export const goBack = () => {
  return goBackRouter()
}

/**
 * Check if route is present in routing config
 * @param {String} id
 * @returns {Boolean}
 */
const pathIdExists = id => {
  if (!id) return false
  return !!ROUTES[id]
}

/**
 * Return path associated to a route id
 * @param {String} id
 * @param {Object} params
 * @returns {String}
 */
export const getPathById = (id, params) => {
  const route = getRouteById(id)
  let path = route.path

  if (path && params) {
    path = getPathFromTemplate(route.linkTemplate || path, params)
  }

  return path
}
