import { uniqBy } from 'lodash'
import actionTypes from 'actions/types'
import { getProductsOffer, getProductsOfferAmount } from 'utils/products'
import { roundCurrency } from 'utils/numerals'

const initialState = {
  citiesAndHubs: {
    loading: false,
    error: null,
    citiesAndHubsList: [],
    selectedHub: null
  },
  sections: {
    loading: false,
    error: null,
    sectionsData: []
  },
  customer: {
    loading: false,
    error: null,
    customer: null,
    customersOptions: []
  },
  cart: {
    loading: false,
    paymentWaitingAuthorization: false,
    hashOrder: null,
    isCancelled: false,
    error: null,
    cart: null,
    status: null,
    paymentMethodsSelected: [],
    discounts: {
      coupon: 0,
      customerCredit: 0,
      companyOfferAmount: 0,
      companyOffer: null,
      companyDiscount: 0
    }
  },
  infoMessage: null
}

const shopReducers = (state = initialState, action) => {
  switch (action.type) {
    // CITIES_AND_HUBS
    case actionTypes.SET_CITIES_AND_HUBS_ACTION:
      return { ...state, citiesAndHubs: { ...state.citiesAndHubs, citiesAndHubsList: action.citiesAndHubs, error: null, selectedHub: null } }
    case actionTypes.SET_SELECTED_HUB_ACTION:
      return { ...state, citiesAndHubs: { ...state.citiesAndHubs, selectedHub: action.selectedHub || null } }
    case actionTypes.SET_CITIES_AND_HUBS_LOADING_START_ACTION:
      return { ...state, citiesAndHubs: { ...state.citiesAndHubs, loading: true, error: null, selectedHub: null } }
    case actionTypes.SET_CITIES_AND_HUBS_LOADING_END_ACTION:
      return { ...state, citiesAndHubs: { ...state.citiesAndHubs, loading: false } }
    case actionTypes.SET_CITIES_AND_HUBS_LOADING_ERROR_ACTION:
      return { ...state, citiesAndHubs: { ...state.citiesAndHubs, error: action.error } }

    // CUSTOMER
    case actionTypes.IDENTIFY_CUSTOMER_LOADING_START_ACTION:
    case actionTypes.GET_CUSTOMERS_OPTIONS_LOADING_START_ACTION:
    case actionTypes.DISIDENTIFY_CUSTOMER_LOADING_START_ACTION:
      return { ...state, customer: { ...state.customer, loading: true } }
    case actionTypes.IDENTIFY_CUSTOMER_LOADING_END_ACTION:
    case actionTypes.GET_CUSTOMERS_OPTIONS_LOADING_END_ACTION:
    case actionTypes.DISIDENTIFY_CUSTOMER_LOADING_END_ACTION:
      return { ...state, customer: { ...state.customer, loading: false } }
    case actionTypes.IDENTIFY_CUSTOMER_ACTION:
      return { ...state, customer: { ...state.customer, customer: action.customer, error: null } }
    case actionTypes.DISIDENTIFY_CUSTOMER_ACTION:
      return { ...state, customer: { ...state.customer, customer: null } }
    case actionTypes.IDENTIFY_CUSTOMER_ERROR_ACTION:
    case actionTypes.GET_CUSTOMERS_OPTIONS_ERROR_ACTION:
      return { ...state, customer: { ...state.customer, error: action.error } }
    case actionTypes.CLEAR_CUSTOMER_ERROR:
      return { ...state, customer: { ...state.customer, error: null } }
    case actionTypes.GET_CUSTOMERS_OPTIONS_ACTION:
      return { ...state, customer: { ...state.customer, customersOptions: action.options } }
    case actionTypes.CLEAR_CUSTOMERS_OPTIONS_ACTION:
      return { ...state, customer: { ...state.customer, customersOptions: [] } }

    // SECTIONS
    case actionTypes.GET_SECTIONS_LOADING_START_ACTION:
      return { ...state, sections: { ...state.sections, loading: true } }
    case actionTypes.GET_SECTIONS_LOADING_END_ACTION:
      return { ...state, sections: { ...state.sections, loading: false } }
    case actionTypes.GET_SECTIONS_SUCCESS_ACTION:
      return { ...state, sections: { ...state.sections, sectionsData: action.data } }
    case actionTypes.GET_SECTIONS_ERROR_ACTION:
    case actionTypes.SET_HUB_PICKER_ERROR_ACTION:
      return { ...state, sections: { ...state.sections, error: action.error } }
    case actionTypes.CLEAR_SECTIONS_ERROR:
      return { ...state, sections: { ...state.sections, error: null } }

    // CART
    case actionTypes.INITIALIZE_CART_LOADING_START_ACTION:
    case actionTypes.SET_CHECKOUT_LOADING_START_ACTION:
    case actionTypes.UPDATE_CART_PRODUCTS_LOADING_START_ACTION:
    case actionTypes.RELOAD_CART_LOADING_START_ACTION:
    case actionTypes.CLEAR_CART_PRODUCTS_LOADING_START_ACTION:
    case actionTypes.UPDATE_CART_DISCOUNTS_LOADING_START_ACTION:
      return { ...state, cart: { ...state.cart, loading: true } }
    case actionTypes.INITIALIZE_CART_LOADING_END_ACTION:
    case actionTypes.SET_CHECKOUT_LOADING_END_ACTION:
    case actionTypes.UPDATE_CART_PRODUCTS_LOADING_END_ACTION:
    case actionTypes.RELOAD_CART_LOADING_END_ACTION:
    case actionTypes.CLEAR_CART_PRODUCTS_LOADING_END_ACTION:
    case actionTypes.UPDATE_CART_DISCOUNTS_LOADING_END_ACTION:
      return { ...state, cart: { ...state.cart, loading: false } }
    case actionTypes.INITIALIZE_CART_ERROR_ACTION:
    case actionTypes.SET_CHECKOUT_ERROR_ACTION:
    case actionTypes.UPDATE_CART_PRODUCTS_ERROR_ACTION:
    case actionTypes.RELOAD_CART_ERROR_ACTION:
    case actionTypes.CLEAR_CART_PRODUCTS_ERROR_ACTION:
    case actionTypes.UPDATE_CART_DISCOUNTS_ERROR_ACTION:
      return { ...state, cart: { ...state.cart, error: action.error } }
    case actionTypes.INITIALIZE_CART_SUCCESS_ACTION:
    case actionTypes.INITIALIZE_CART_NON_BLOCKING_ERRORS_ACTION:
    case actionTypes.UPDATE_CART_PRODUCTS_SUCCESS_ACTION:
    case actionTypes.UPDATE_CART_PRODUCTS_NON_BLOCKING_ERRORS_ACTION:
    case actionTypes.RELOAD_CART_SUCCESS_ACTION:
    case actionTypes.RELOAD_CART_NON_BLOCKING_ERRORS_ACTION:
    case actionTypes.UPDATE_CART_DISCOUNTS_SUCCESS_ACTION:
    case actionTypes.UPDATE_CART_DISCOUNTS_NON_BLOCKING_ERRORS_ACTION:
      return {
        ...state,
        cart: {
          ...state.cart,
          error: null,
          cart: action.cart,
          paymentMethods: action.paymentMethods || state.cart.paymentMethods,
          status: action.status,
          discounts: {
            ...state.cart.discounts,
            coupon: action.discount ? action.discount : null,
            companyDiscount: action.cart.companyDiscount,
            customerCredit: action.cart.customerCredit,
            companyOfferAmount: action?.cart?.products ? getProductsOfferAmount(action.cart.products) : state.cart.discounts.companyOfferAmount,
            companyOffer: action?.cart?.products ? getProductsOffer(action.cart.products) : state.cart.discounts.companyOffer
          }
        }
      }
    case actionTypes.SET_SHOP_INFO_MESSAGE_ACTION:
    case actionTypes.DISIDENTIFY_CUSTOMER_ERROR_ACTION:
      return { ...state, infoMessage: action.content }
    case actionTypes.CLEAR_SHOP_INFO_MESSAGE_ACTION:
      return { ...state, infoMessage: null }
    case actionTypes.SELECT_PAYMENT_METHOD:
      return { ...state, cart: { ...state.cart, paymentMethodsSelected: action.selected } }
    case actionTypes.CLEAR_CHECKOUT_ERROR:
    case actionTypes.CLEAR_CART_DISCOUNTS_ERROR:
      return { ...state, cart: { ...state.cart, error: null } }
    case actionTypes.CLEAR_CART_ACTION:
      return { ...state, cart: { ...state.cart, error: null, cart: null, status: null, paymentMethodSelected: [] } }
    case actionTypes.PAYMENT_PROCESSING_ACTION:
    case actionTypes.CONFIRM_PAYMENT_ACTION_SUCCESS:
      return { ...state, cart: { ...state.cart, paymentWaitingAuthorization: action.isLoading, hashOrder: action.hashOrder, isCancelled: false } }
    case actionTypes.CONFIRM_PAYMENT_ACTION_ERROR:
      return { ...state, cart: { ...state.cart, paymentWaitingAuthorization: false, hashOrder: null, error: action.error, isCancelled: false } }
    case actionTypes.CANCEL_PAYMENT_ACTION:
      return { ...state, cart: { ...state.cart, isCancelled: true } }
    default:
      return state
  }
}

/* customer */
export const getCustomer = (state) => state.shop.customer.customer
export const getCustomerError = (state) => state.shop.customer.error
export const getCustomersOptions = (state) => state.shop.customer.customersOptions

/* sections */
export const getSectionsError = (state) => state.shop.sections.error
export const getSections = (state) => state.shop.sections
export const getShopPublications = (state) => {
  const shopPublications = []
  if (Array.isArray(state?.shop?.sections?.sectionsData)) {
    for (const section of state.shop.sections.sectionsData) {
      if (Array.isArray(section?.publications)) {
        for (const { idProductPublication, price, title, productTypeName, reference, isReusable, otherPublications, endSaleDt } of section.publications) {
          shopPublications.push({
            idProductPublication,
            price,
            title,
            productTypeName,
            code: reference,
            isReusable,
            otherPublications,
            endSaleDt
          })
        }
      }
    }
  }
  return uniqBy(shopPublications, ({ idProductPublication }) => idProductPublication)
}
/* cart */
export const getCart = (state) => state.shop.cart
export const getPaymentMethodSelected = (state) => state.shop.cart.paymentMethodsSelected || 0
export const getCartUUID = (state) => state.shop.cart.cart?.uuid || null
export const getCartError = (state) => state.shop.cart.error
export const getCartLoading = (state) => state.shop.cart.loading
export const getPaymentWaitingAuthorization = (state) => state.shop.cart.paymentWaitingAuthorization
export const getHashOrder = (state) => state.shop.cart.hashOrder
export const getIsCancelled = (state) => state.shop.cart.isCancelled
export const getCartPaymentMethods = (state) => state.shop.cart.paymentMethods

/* cart products */
export const getProductQuantity = (state, props) => {
  const { publicationId } = props
  if (Array.isArray(state?.shop?.cart?.cart?.products)) {
    for (const { items } of state.shop.cart.cart.products) {
      if (Array.isArray(items)) {
        for (const { idPublication, quantity } of items) {
          if (idPublication === publicationId && Number.isFinite(quantity)) {
            return quantity
          }
        }
      }
    }
  }
  return 0
}

export const getCartProducts = (state) => {
  const cartProducts = []
  if (Array.isArray(state?.shop?.cart?.cart?.products)) {
    for (const { items } of state.shop.cart.cart.products) {
      if (Array.isArray(items)) {
        items.reduce((previous, current) => {
          const { idPublication, reference, title, originalPrice, quantity, isReusable, type } = current
          const idx = previous.findIndex(value => value.idPublication === idPublication)
          if (idx !== -1) {
            previous[idx].quantity += quantity
          } else {
            if (type !== 'REUSABLE_PACKAGING') {
              previous.push({
                idPublication,
                code: reference,
                title,
                originalPrice,
                quantity,
                isReusable,
                type
              })
            }
          }
          return previous
        }, cartProducts)
      }
    }
  }
  return uniqBy(cartProducts, ({ idPublication }) => idPublication)
}

export const getCartOffer = (state) => {
  let offerDetails
  if (Array.isArray(state?.shop?.cart?.cart?.products)) {
    for (const { offer } of state.shop.cart.cart.products) {
      offerDetails = offer
    }
  }
  return offerDetails
}

/* cart deposit details */
export const getCartDepositDetails = (state) => {
  let depositQuantity = 0
  const depositPrice = 2
  if (Array.isArray(state?.shop?.cart?.cart?.products)) {
    for (const { items } of state.shop.cart.cart.products) {
      if (Array.isArray(items)) {
        // change when api call done
        for (const { isReusable, quantity } of items) {
          if (isReusable === true) depositQuantity = depositQuantity + quantity
        }
      }
    }
  }
  return { quantity: depositQuantity, total: depositQuantity * depositPrice }
}
/* shop info */
export const getShopInfoMessage = (state) => state.shop.infoMessage

/* cart ammounts */
export const getCustomerCredit = (state) => state.shop.cart.cart?.customerCredit || 0
export const getCompanyDiscount = (state) => state.shop.cart.cart?.companyDiscount || 0
export const getCompanySetting = (state) => state.shop.cart.cart?.companySetting || null
export const getCompanyOfferTotalAmount = (state) => state.shop.cart.discounts.companyOfferAmount || 0
export const getCompanyOffer = (state) => state.shop.cart.discounts.companyOffer || null
export const getCoupon = (state) => state.shop.cart.discounts.coupon || null

export const getTotalDiscount = (state) => {
  const customerCredit = getCustomerCredit(state)
  const companyDiscount = getCompanyDiscount(state)
  const companyOffer = getCompanyOfferTotalAmount(state)
  const coupon = getCoupon(state)?.discountAmount || 0
  return customerCredit + companyDiscount + companyOffer + coupon
}

// Total before discount(s) and deposit(s)
export const getSubTotal = (state) => {
  const products = getCartProducts(state)
  let total = 0
  products.length !== 0 && products.forEach(product => {
    const { originalPrice, quantity } = product
    total = total + originalPrice * quantity
  })
  return total
}

export const getTotalDue = (state) => {
  const subTotal = getSubTotal(state)
  const discounts = getTotalDiscount(state)
  const deposit = getCartDepositDetails(state)
  const totalDue = deposit.quantity > 0 ? subTotal + deposit.total - discounts : subTotal - discounts
  return totalDue < 0 ? 0 : roundCurrency(totalDue)
}

/* cities and hubs */
export const getCitiesAndHubs = (state) => state?.shop?.citiesAndHubs?.citiesAndHubsList || []
export const getSelectedHub = (state) => state?.shop?.citiesAndHubs?.selectedHub
export const getCitiesAndHubsLoadingError = (state) => state?.shop?.citiesAndHubs?.error
export const getCitiesAndHubsLoadingState = (state) => state?.shop?.citiesAndHubs?.loading || false

export const MAXIMUM_TR_CREDIT = 19
export default shopReducers
