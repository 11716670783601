
export const roundCurrency = amount => {
  if (!amount) {
    return 0
  }
  amount = parseFloat(amount)
  if (isNaN(amount)) {
    return 0
  }
  return Math.round(amount * 100) / 100
}
