import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import CartHeader from 'components/shop/CartHeader'
import ProductsMenu from 'components/shop/ProductsMenu'
import CartFooter from 'components/shop/CartFooter'
import { useSelector, useDispatch } from 'react-redux'

import { getCartError, getHashOrder, getPaymentWaitingAuthorization, getTotalDiscount } from 'reducers/shop'
import { clearCheckoutError, confirmPayment, resetCartOrder, selectPaymentMethod } from 'actions/shop/cart'

const CardPayment = ({ products, onNext, onPrev, step, currentStep }) => {
  const { title, sort } = step
  const hashOrder = useSelector(getHashOrder)
  const paymentWaitingAuthorization = useSelector(getPaymentWaitingAuthorization)
  const error = useSelector(getCartError)
  const result = { error, data: hashOrder }
  const [intervalId, setIntervalId] = useState(null)
  const isProcessing = useSelector(getPaymentWaitingAuthorization)
  const totalDiscount = useSelector(getTotalDiscount)
  const dispatch = useDispatch()

  const stepFields = {
    result,
    isLoading: paymentWaitingAuthorization,
    hasProducts: !isEmpty(products)
  }
  const handleConfirmPayment = async () => {
    await dispatch(confirmPayment(hashOrder, onNext))
  }

  useEffect(() => {
    if (hashOrder && !intervalId) {
      const id = setInterval(() => {
        handleConfirmPayment()
      }, 3000)
      setIntervalId(id)
    }
    if (!hashOrder && intervalId) {
      clearInterval(intervalId)
      setIntervalId(null)
    }
  }, [hashOrder])

  const handleCancel = () => {
    onPrev()
    dispatch(selectPaymentMethod())
    dispatch(clearCheckoutError())
    dispatch(resetCartOrder())
  }

  if (currentStep !== sort) return null
  return (
    <div>
      <CartHeader title={title} handleCancel={handleCancel} isProcessing={isProcessing} />
      <ProductsMenu products={products} isCartPayment />
      <CartFooter currentStep={currentStep} stepFields={stepFields} canRemoveCustomer={false} showDiscountInfo={totalDiscount > 0} />
    </div>
  )
}

export default CardPayment
