import React from 'react'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import { isEmpty } from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { ERROR_CANCELLED_PAYMENT } from 'utils/errors'

import { getCartPaymentMethods, getCartProducts, getTotalDue, MAXIMUM_TR_CREDIT } from 'reducers/shop'
import { clearCheckoutError, payByCard, cancelPayment, payWithDiscounts, resetCartOrder } from 'actions/shop/cart'

import CustomerIdentifier from './CustomerIdentifier'
import ExpensesSummary from './ExpensesSummary'

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: 'auto'
  },
  customer: {
    margin: '15px'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: [[28, 32]]
  },
  customButtonTrp: {
    height: 36,
    color: '#2196f3',
    border: '1px solid #2196f3',
    '&:disabled': {
      color: '#959595',
      border: '1px solid #A6A6A6',
      backgroundColor: 'white'
    }
  },
  customButtonCart: {
    height: 36,
    flexGrow: ({ isMealVoucherActive }) => isMealVoucherActive ? 0 : 1
  },
  button: {
    flexShrink: 0,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  message: {
    margin: 0,
    padding: 10,
    color: ({ error }) => error ? error === ERROR_CANCELLED_PAYMENT ? 'black' : 'red' : '#2196f3',
    fontWeight: ({ error }) => error !== ERROR_CANCELLED_PAYMENT ? 'bold' : 'normal'
  },
  center: {
    alignItems: 'center',
    flexFlow: 'column nowrap'
  }
})

const CartFooter = ({ currentStep, stepFields, showCoupon = false, showDiscountInfo = false, canRemoveCustomer = true }) => {
  const dispatch = useDispatch()
  const goToTRPayment = stepFields?.onNext?.trPayment
  const goToCardPayment = stepFields?.onNext?.cardPayment
  const goToConfirm = stepFields?.onNext?.confirm
  const totalDue = useSelector(getTotalDue)
  const products = useSelector(getCartProducts)
  const cartPaymentMethods = useSelector(getCartPaymentMethods)
  const isMealVoucherActive = cartPaymentMethods?.mealVoucher?.isActive
  const onNext = stepFields?.onNext
  const classes = useStyles({ error: stepFields.result?.error, isMealVoucherActive })

  const handleRetry = () => {
    dispatch(clearCheckoutError())
    dispatch(payByCard())
  }

  const handleCancel = () => {
    dispatch(cancelPayment())
    dispatch(resetCartOrder())
  }

  const handlePayWithDiscounts = () => {
    dispatch(payWithDiscounts({ onSuccess: goToConfirm }))
  }

  return (
    <div className={classes.root}>
      <div className={classes.customer}>
        <CustomerIdentifier showIdentificationStatus hasProducts={stepFields.hasProducts} canRemoveCustomer={canRemoveCustomer} />
      </div>
      <ExpensesSummary showHeader={false} showCoupon={showCoupon} showDiscountInfo={showDiscountInfo} />
      {currentStep === 1
        ? totalDue === 0 && !isEmpty(products)
            ? (
              <div className={clsx(classes.container, classes.center)}>
                <Button variant='contained' size='medium' color='primary' onClick={handlePayWithDiscounts}>Finaliser la commande</Button>
              </div>)
            : (
              <div className={classes.container}>
                {isMealVoucherActive
                  ? (
                    <>
                      <Button disabled={stepFields.isDisabled || totalDue > MAXIMUM_TR_CREDIT} className={clsx(classes.button, classes.customButtonTrp)} variant='contained' size='medium' color='primary' onClick={goToTRPayment}>tr papier</Button>
                      <Button disabled={stepFields.isDisabled} variant='contained' size='medium' color='primary' className={classes.customButtonCart} onClick={goToCardPayment}>Payer par carte</Button>
                    </>
                    )
                  : (
                    <Button disabled={stepFields.isDisabled} variant='contained' size='medium' color='primary' className={classes.customButtonCart} onClick={goToCardPayment}>Procéder au paiement</Button>
                    )}
              </div>)
        : currentStep === 2
          ? (
            <div className={clsx(classes.container)}>
              <p className={classes.message} color='secondary'>{!stepFields.isLoading && stepFields.result.error ? stepFields.result.error : 'Paiement en cours ...'}</p>
              {!stepFields.isLoading && stepFields.result.error ? <Button size='medium' onClick={handleRetry} variant='outlined' color='primary' className={classes.button}>Réessayer</Button> : <Button onClick={handleCancel}>Annuler</Button>}
            </div>)
          : currentStep === 5
            ? (
              <div className={clsx(classes.container, classes.center)}>
                <Button variant='contained' size='medium' color='primary' onClick={onNext}>Finaliser la commande</Button>
              </div>)
            : null}
    </div>
  )
}

export default CartFooter
