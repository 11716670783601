import React from 'react'
import { useSelector } from 'react-redux'
import { getCartProducts } from 'reducers/shop'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: 'transparent',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center'
  },
  header: {
    padding: 15,
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '18.75px',
    textAlign: 'left',
    width: '100%'
  },
  body: {
    display: 'flex',
    flexFlow: 'column nowrap',
    backgroundColor: '#ffffff',
    padding: '5px 10px',
    width: '100%',
    maxWidth: '800px'
  },
  summaryItem: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '35px'
  },
  itemName: {
    minWidth: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  itemValue: {
    flexShrink: 0,
    minWidth: '12%',
    textAlign: 'right'
  }
})

const OrderSummary = () => {
  const classes = useStyles()
  const products = useSelector(getCartProducts)
  return (
    <div className={classes.root}>
      <div className={classes.header}>Récapitulatif de la commande</div>
      <div className={classes.body}>
        {
          products.map(({ code, title, quantity }) => (
            <div key={code} className={classes.summaryItem}>
              <div className={classes.itemName}>{code} - {title}</div>
              <div className={classes.itemValue}>x {quantity}</div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default OrderSummary
