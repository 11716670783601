export const ERROR_INVALID_EMAIL = 'Adresse email invalide'
export const ERROR_REQUIRED_FIELD = 'Champ obligatoire'

export const ERROR_CONNECTION = 'Votre connexion est perturbée et ne vous a pas permis de poursuivre. Vérifiez vos paramètres de connexion et tentez à nouveau.'
export const ERROR_LOGIN = 'Oups ! Il semblerait que vous vous soyez trompé sur les informations saisies. Nous vous invitons à essayer de nouveau.'

export const GENERIC_ERROR_MESSAGE = { title: 'Une erreur est survenue.', message: 'Veuillez rafraîchir la page et réessayer.' }
export const ERROR_UNKNOWN_PAYMENT = 'Une erreur est survenue lors du paiement.'
export const ERROR_CANCELLED_PAYMENT = 'Le paiement a été annulé.'
export const ERROR_MISSING_PARAMETERS = 'Manque de parametres pour finaliser l\'action'

export const ERROR_ASSIGN_CART_TO_CUSTOMER = 'Une erreur est survenue lors de l\'identification. Veuillez rééssayer.'
export const ERROR_IDENTIFY_CUSTOMER_BY_ID = 'Une erreur est survenue lors de l\'identification par qr code. Veuillez rééssayer.'
export const ERROR_IDENTIFY_CUSTORMER_BY_PHONE = 'Aucun résultat ne correspond à votre recherche.'
export const GENERIC_ERROR = 'Oups ! Une erreur est survenue. Veuillez rafraîchir la page et réessayer.'
export const ERROR_HUB_NOT_FOUND = 'Oups ! Une erreur est survenue. Veuillez sélectionner un autre hub ou contacter l\'équipe produit.'
export const GENERIC_SHOP_FORM_ERROR = 'Oups ! Une erreur est survenue. Veuillez réessayer ou contacter l\'équipe produit.'

export const ERROR_CART_DISCOUNT_INVALID = 'Code promo invalide'
