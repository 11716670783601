import React from 'react'
import { useSelector } from 'react-redux'

import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'

import { getShopInfoMessage } from 'reducers/shop'

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: 500,
    minHeight: 200,
    display: 'flex',
    flexFlow: 'column nowrap',
    backgroundColor: '#ffffff',
    borderRadius: 10,
    padding: 30
  },
  closeIcon: {
    alignSelf: 'flex-end',
    position: 'absolute',
    right: 10,
    top: 10
  },
  title: {
    fontSize: '20px',
    lineHeight: '30px',
    padding: '20px 0',
    textAlign: 'center'
  },
  message: {
    textAlign: 'center',
    fontSize: '15px'
  }
})

const ShopInfoMessage = ({ onClose = () => {} }) => {
  const classes = useStyles()
  const { title, message } = useSelector(getShopInfoMessage)

  return (
    <div className={classes.root}>
      <IconButton onClick={onClose} className={classes.closeIcon}>
        <CloseIcon />
      </IconButton>
      <div className={classes.title}>
        {title}
      </div>
      <div className={classes.message}>
        {message}
      </div>
    </div>
  )
}

export default ShopInfoMessage
