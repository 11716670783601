import React from 'react'
import { withRouter } from 'react-router'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row'
  },
  mainContent: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    overflow: 'auto'
  }
}))

const Root = ({ children, location }) => {
  const classes = useStyles()

  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <div className={classes.mainContent}>
          {children}
        </div>
      </div>
    </>
  )
}

export default withRouter(Root)
